export const sheet6 = [
  {
    id: "龙湖成都蜀新天街",
    belong: "郫筒街道",
    belongEn: "Pitong Street",
    rName: "龙湖成都蜀新天街",
    rNameEn: "Longfor Shuxing Paradise Walk",
    cover: ["/sheet6/1-1-1.jpg"],
    description:
      "成都蜀新天街是龙湖集团在成都开业的第十座购物中心，毗邻郫都新城中心位置，体量约12万方，地下一层与地铁6号线直接接驳。杜鹃花、子规、山脉蜀韵等设计元素贯穿各场景中，在彰显郫都蜀韵特色的主体下，整体营造出现代、年轻、时尚的建筑氛围，迎合年轻客群的潮购消费诉求。是融合智能办公基地、文化创意产业基地、星级酒店、会议中心、购物中心、饮食之都为一体的外向型城市商业中心。",
    descriptionEn:
      "Longhu Chengdu shuxin tianjie is the tenth shopping center opened by Longhu Group in Chengdu. It is located adjacent to the center of Pidu New City, with a volume of approximately 120,000 square meters, and directly connected to Line 6 of the subway on the basement level. Design elements such as rhododendrons, cuckoos, and Shu-style mountains are incorporated into various scenes, creating a modern, youthful, and fashionable architectural atmosphere while highlighting the unique features of Pidu. It caters to the consumption demands of young customers who pursue trendy shopping. It is an outward-looking urban commercial center that integrates intelligent office bases, cultural and creative industry bases, star hotels, conference centers, shopping centers, and a food city.",
    feature: "现代、年轻、时尚中外美食齐聚的“城市乐享生活中心“",
    featureEn:
      "City life center, Where modern, youthful, and fashionable meet with a variety of international cuisine.",
    address: "成都市郫都区创智北环路创智公园东侧约100米",
    addressEn:
      "Approximately 100 meters east of  Creativity Park,Creativity North Ring Road, Pidu District, Chengdu",
    longLatitude: "103.90,30.79",
    longLatitudeId: "B0HRYUGT7E",
    left: 918,
    top: 606,
    width: 102,
    height: 78,
  },
  {
    id: "海骏达城",
    rName: "海骏达城",
    rNameEn: "Haijun Da City",
    cover: ["/sheet6/2-1-1.jpg"],
    description:
      "海骏达城占地5万㎡，由两座独栋商业楼和一条中心商业街构成，7条空中连廊实现了楼宇间的无缝连接，共有门店58个。定位为玩味潮流街，引进了巴国布衣、印象李庄、万物生江北老火锅等众多极具代表性的餐饮品牌。“公园+主题街区”模式使商业街与创智公园实现360度环抱，现代商业与绿色生态的相互交融，在满足都市新型消费者物质与精神的双重需求同时，也给消费者带来了全新的消费场景体验。",
    descriptionEn:
      'Haijunda City covers an area of 50,000 square meters and consists of two standalone commercial buildings and a central commercial street. Seven aerial corridors seamlessly connect the buildings, and a total of 58 stores are located within the complex. It is positioned as a trendy and playful street and has introduced many representative dining brands, such as Baguobuyi, Impression Li Zhuang, and Wanwusheng Jiangbei Old Hotpot. The "Park + Theme Street" model surrounds the commercial street and Creativity Park in a 360-degree embrace, blending modern commerce with green ecology. It satisfies the dual needs of material and spiritual consumption for new urban consumers while bringing a new consumption scene experience to customers. ',
    feature: "玩味潮流街+酒吧一条街，火锅、川菜、烧烤、汤锅等各类美食应享尽享",
    featureEn:
      "Playful trendy street plus bar district where all kinds of delicious food can be enjoyed, including hotpot, Sichuan cuisine, barbecue, and hot soup.",
    address: "成都市郫都区郫筒街道创智北环路30号",
    addressEn: "No. 30, Creativity North Ring Road, Pitong Street, Pidu District, Chengdu",
    longLatitude: "103.90,30.79",
    longLatitudeId: "B0FFIPSUBE",
    left: 765,
    top: 564,
    width: 57,
    height: 42,
    belong: "郫筒街道",
    belongEn: "Pitong Street",
  },
  {
    id: "蜀都万达",
    rName: "蜀都万达",
    rNameEn: "Shudu Wanda Plaza",
    cover: ["/sheet6/3-1-1.jpg"],
    description:
      "蜀都万达广场总建筑面积约90万㎡，汇聚购物中心shopping mall 、多元商业街区、高档住宅群、SOHO公寓4大业态；聚合烤匠、星巴克、鲜芋仙、销魂掌、火上芭蕾、意风小镇、味千拉面、黄记煌三汁焖锅等近千品牌商家，以吃、喝、玩、乐一体化的体验式消费，颠覆城西商业格局。蜀都万达广场作为万达集团第3.5代体验式商业综合体的升级版，是郫都区川派餐饮美食聚集地之一。",
    descriptionEn:
      "Shudu Wanda Plaza has a total construction area of about 900,000 square meters and brings together four major business formats: a shopping mall, a diversified commercial district, high-end residential communities, and SOHO apartments. It features nearly a thousand branded merchants such as Kaojiang, Starbucks, Xian Yuxian, Xiaohun zhang, Fire Ballet, Italian-style town, Wakiya Ramen, and Huangji Huang Three Juices Stewpot, offering integrated experiential consumption that combines eating, drinking, playing, and entertainment, subverting the commercial pattern in the west of the city. As an upgraded version of Wanda Group's 3.5-generation experiential commercial complex, Shudu Wanda Plaza is one of the gathering places for Sichuan-style cuisine in Pidu District. ",
    feature: "购物中心 多元商业街，川派美食集聚区，一站式体验吃、喝、玩、乐",
    featureEn:
      "Shoping center with a diverse commercial street and a cluster of Sichuan-style cuisine. A one-stop destination for experiencing food, drinks, entertainment, and leisure.",
    address: "成都市郫都区望丛东路139号",
    addressEn: "No. 139, East Wang Cong Road, Pidu District, Chengdu",
    longLatitude: "103.91,30.81",
    longLatitudeId: "B0FFJ9PCKN",
    left: 897,
    top: 474,
    width: 141,
    height: 90,
    belong: "郫筒街道",
    belongEn: "Pitong Street",
  },
  {
    id: "太清美食街",
    rName: "太清美食街",
    rNameEn: "Taiqing Food Street",
    cover: ["/sheet6/4-1-1.jpg"],
    description:
      "太清路美食街为郫都区餐饮业发展中餐饮经营单位自发聚集形成，南起郫花路北至213国道，全长2700米，以摸底河为界，摸底河以北1990米由郫筒街道管辖，摸底河以南710米由友爱镇管辖。太清路美食街共聚集餐饮商家20余家，其中铜壶苑、泰清锦宴、汇鑫苑、杨鸡肉陌上人家、印象泰和园宴会艺术中心属于限上餐饮企业，臻里捌号宴会中心、喜木花园餐厅、小南亚花园餐厅、海棠里、小院子、流氓兔、小缘故事、龙门阵柴火鸡、宜宾特色鲫鱼、拾光小院烤肉小火锅、李家大院土鸡馆、金绿园休闲庄、花开半夏生活馆等属于大中型餐饮经常单位，让八方食客尽享川派餐饮美食味道。",
    descriptionEn:
      "Taiqing road food Street is a spontaneously formed gathering place for catering businesses in the development of the catering industry in Pidu District. It runs from the south of Pihua Road to the north of National Highway 213, with a total length of 2,700 meters, and is divided by Modi River. The section north of Modi River, which is 1,990 meters long, is under the jurisdiction of Pitong Street, while the section south of Modi River, which is 710 meters long, is under the jurisdiction of You'ai Town. Taiqing Road Food Street has more than 20 catering businesses, including Copper Pot Garden, Taiqing Jin Yan, Huixin Yuan, Yang Chicken Roadside Inn, and Impression Taihe Garden Banquet Art Center, which are all listed as high-end catering enterprises. Other large and medium-sized catering units include Zhenli Ba No.8 Banquet Center, Ximuhua Garden Restaurant, Xiaonanya Garden Restaurant, Haitangli, Xiaoyuanzi, Rogue Rabbit, Xiaoyuanguosi Barbecue and Hotpot, Lijia Courtyard Chicken House, Jinlvyuan Leisure Farm, and Huakai Banxia Living Hall, allowing food lovers from all over to enjoy Sichuan-style catering delicacies.",
    feature: "川派餐饮商业集聚区，郫都区婚宴一条街，让八方食客尽享郫都美食味道",
    featureEn:
      "Sichuan-style cuisine and beverage commercial cluster, with a wedding banquet street in Pidu district.Let tourist from everywhere to enjoy the taste of Pidu's cuisine.",
    address: "成都市郫都区太清路",
    addressEn: "Taiqing Road, Pidu distrct, Chengdu",
    longLatitude: "103.86,30.83",
    longLatitudeId: "BZAAN300F8",
    left: 573,
    top: 477,
    width: 33,
    height: 96,
    belong: "郫筒街道",
    belongEn: "Pitong Street",
  },
  {
    id: "石犀里",
    belong: "犀浦街道",
    belongEn: "Xipu Street",
    rName: "石犀里",
    rNameEn: "Shixili Commercial Theme Park",
    cover: ["/sheet6/5-1-1.jpg"],
    description:
      "石犀里以现代时尚和历史底蕴相结合的主题建筑和街区设计，打造主题性强、元素鲜明、差异化明显、具有格调与品质的开放式主题商业公园，重点引进体验式业态和特色餐饮，打造一站式休闲娱乐中心。其中包括C+影城、奇迹健身、星巴克、麦当劳、豪客来、犀乐门KTV、热风、半糖网咖、集渔泰式火锅等一批全球知名度高，市场认知度好的品牌，构建城西人文商业新标杆围绕于现代年轻人吃喝玩乐的需求。",
    descriptionEn:
      "Shixi Li is a themed commercial park in Chengdu's Pidu district, combining modern fashion with historical heritage in its architecture and street design. It aims to create an open, theme-oriented commercial park with a strong theme, distinctive elements, style, and quality. The park focuses on experiential formats and specialty dining, creating a one-stop leisure and entertainment center. Among the tenants are globally renowned brands such as C+ Cinemas, Anytime Fitness, Starbucks, McDonald's, Outback Steakhouse, Xi Le Men KTV, Re Feng tea house, Half Sugar Internet café, and Ji Yu Thai-style hot pot, constructing a new benchmark for cultural and commercial activities in the west of Chengdu, catering to the needs of modern young people for eating, drinking, and entertainment.",
    feature: "体验式业态+特色餐饮，一站式休闲娱乐中心，具有格调与品质的开放式主题商业公园",
    featureEn:
      "Experience-based formats and distinctive dining, an one stop leisure and entertainment center.An open-themed commercial park with style and quality.",
    address: "成都市郫都区犀浦街道华都路120号",
    addressEn: " No. 120, Huadu Road, Xipu Street,Pidu distrct,Chengdu",
    longLatitude: "103.98,30.76",
    longLatitudeId: "B0G2990VBF",
    left: 1229,
    top: 610,
    width: 88,
    height: 90,
  },
  {
    id: "爱琴海购物公园",
    rName: "爱琴海购物公园",
    rNameEn: "Aegean Sea Shopping Center",
    cover: ["/sheet6/6-1-1.jpg"],
    description:
      "项目打造区域周边一站式购物、餐饮、娱乐生活空间，解决就近生活消费需求；填补百货零售、餐饮娱乐、儿童体验、运动休闲及生活配套等消费者亟待需求的市场空白；通过塑造IP形象，打造项目独有标签进而维护VIP粉丝活跃度；同时购物公园和玫瑰金街深度联动，服务片区3-5km范围内人群的同时，针对不同客群的特性，着力区域消费能级的提升，形成餐饮商业集聚效应，现已形成了麦当劳、蜀皇全牛宴、汉堡王、千叶料理等餐饮知名名店，以此吸聚区域外客群提升区域的消费能级，满足更多元化的消费需求。",
    descriptionEn:
      "Aegean Shopping Park is a project aimed at creating a one-stop shopping, dining, and entertainment space in the surrounding area, meeting the needs of nearby residents for convenient consumption. It fills the market gaps in department store retail, dining and entertainment, children's experiences, sports and leisure, and living facilities urgently needed by consumers. By shaping its own unique IP image, the project aims to maintain the active engagement of VIP fans. At the same time, the shopping park and Rose Gold Street are deeply linked, serving people within a 3-5km radius while focusing on improving the consumption level of different customer groups. This creates a gathering effect for dining and commercial activities. Known restaurants such as McDonald's, Shuhuang quanniu banquet, Burger King, and Qianye Japanese Cuisine have already formed. This attracts customers from outside the region to upgrade the consumption level of the area and meet more diverse consumer needs.",
    feature: "一站式购物、餐饮、娱乐生活空间，满足多元化消费需求",
    featureEn:
      "A place include shopping, dining, and entertainment living space that meets diverse consumer needs.",
    address: "成都市郫都区犀浦街道国宁东路919号",
    addressEn: " No.919, East Guoning Road, Xipu Street, Pidu District, Chengdu",
    longLatitude: "103.99,30.74",
    longLatitudeId: "B0FFJ49616",
    left: 1242,
    top: 777,
    width: 132,
    height: 102,
    belong: "犀浦街道",
    belongEn: "Xipu Street",
  },
  {
    id: "龙城红光里",
    belong: "红光街道",
    belongEn: "Hongguang Street",
    rName: "龙城红光里",
    rNameEn: "Dragon City Hongguangli",
    cover: ["/sheet6/7-1-1.jpg"],
    description:
      "志达地产旗下首个城市综合体，总规划建筑面积约25万㎡，涵盖购物广场、办公、智能SOHO、情景街区等，包罗繁华万象，将打造成继太古里、锦里后，成都未来第三里，成为城西极具特色的“森林主题”购物公园城。项目周边商业氛围成熟，是片区的商业核心区，四期美食街2020年被评为郫都区十大美食消费新场景。",
    descriptionEn:
      'Longcheng Hongguangli is the first urban complex under Zhida Real Estate, with a total planned construction area of about 250,000 square meters. It includes a shopping mall, offices, smart SOHO, and a themed block, covering a bustling scene, and will be built as the third landmark in Chengdu, following Taikoo Li and Jinli. It will become a unique "forest-themed" shopping park city in the west of the city. The surrounding commercial atmosphere of the project is mature and is the core commercial area of the area. The fourth phase of the food street was selected as one of the top ten new food consumption scenes in Pidu District in 2020. ',
    feature:
      "城西极具特色的“森林主题”购物公园城，涵盖购物广场、办公、智能SOHO、情景街区，郫都区十大美食消费新场景",
    featureEn:
      "The forest-themed shopping park in the western of the city, featuring a shopping mall, offices, intelligent SOHO, and thematic neighborhoods. One of the top 10 new place to enjoy food and consumption in Pidu district.",
    address: "成都市郫都区成灌高铁（红光站）与地铁6号线（红高路站）之间",
    addressEn:
      "Located between Chengdu-Guangan high-speed railway (Hongguang Station) and Metro Line 6 (Honggaolu Station), Pidu District, Chengdu",
    longLatitude: "103.94,30.77",
    longLatitudeId: "B0FFKR1HX8",
    left: 1038,
    top: 528,
    width: 57,
    height: 105,
  },
  // {
  //   id: "卤味坊",
  //   rName: "卤味坊",
  //   rNameEn: "Name: Luwei Fang",
  //   cover: ["/sheet6/8-1-1.jpg"],
  //   feature:
  //     "生态、业态、文态、形态“四态”融合发展，“主力店+品牌店+明星店+特色店”四位一体，焕新郫都味道与市井烟火，诠释沱江河两岸休闲慢生活。",
  //   featureEn:
  //     'Luwei Fang is a fusion development of "Four States" - ecological, commercial, cultural, and architectural. It combines the main stores, branded stores, star stores, and specialty stores, revitalizing the taste and vibrancy of the Pidu Plain and interpreting a leisurely and slow-paced lifestyle along the banks of the Tuo River.',
  //   address: "郫都区菠萝街187号",
  //   addressEn: "No. 187, Boluo Street, Pidu District",
  //   longLatitude: "103.88,30.82",
  //   longLatitudeId: "BZAAN400VA",
  //   left: 0,
  //   top: 0,
  //   width: 0,
  //   height: 0,
  // },
  {
    id: "世界科幻公园",
    rName: "世界科幻公园",
    rNameEn: "World Sci-Fi Park",
    cover: ["/sheet6/9-1-1.jpg"],
    feature:
      "科幻与美食巧妙融合，西式快餐、咖啡为主的轻餐饮，汇聚非遗特色美食、中华名小吃、成都本地美食、科幻创意美食的特色美食集市，高品质主题餐厅，满足多元化美食体验需求。",
    featureEn:
      "World Sci-Fi Park cleverly combines science fiction with food, It brings together a marketplace of distinctive cuisine, including intangible cultural heritage specialties, Chinese famous snacks, local Chengdu cuisine, and creatively themed sci-fi cuisine, with high-quality themed restaurants, and “western-style fast food and coffee-centric light dining”, which caters to diverse culinary experiences.",
    address: "郫都区德源街道菁蓉湖公园",
    addressEn: "Jingrong Lake Park, Deyuan Street, Pidu District",
    longLatitude: "103.86,30.79",
    longLatitudeId: "B0J1AC6NDK",
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  },
];
