// 由于相似的工会地图占用的sheet8、9、10三个文件夹，因此这里使用sheet11
export const sheet8 = [
  {
    id: "成都孔师傅食品有限公司",
    rName: "成都孔师傅食品有限公司",
    rNameEn: "",
    cover: ["/sheet11/孔师傅1.jpg", "/sheet11/孔师傅2.jpg"],
    description:
      "成都孔师傅食品有限公司成立于1989年，是中国西部地区专业生产传统腌腊制品、调味品的龙头企业，规模较大，技术力量雄厚，产品质量过硬的四川老字号驰名企业。主要从事腌腊制品、调味料制品及果切的研发、生产、品牌运营及销售一体化的现代化食品经营业务，产品包括蒸肉米粉、香辛料等川式调味、川味腌腊制品等川味美食。",
    descriptionEn: "",
    feature:
      "成都孔师傅食品有限公司为民营企业，始于一九八九年，是中国西部地区专业生产传统腌腊制品、调味品的龙头企业。",
    featureEn: "",
    address: "安德街道",
    addressEn: "",
    phone: "15982488121",
    longLatitude: "",
    longLatitudeId: "BOUDPCPSLH",
    left: 510,
    top: 290,
    width: 85,
    height: 55,
  },
  {
    id: "四川饭扫光食品集团股份有限公司",
    rName: "四川饭扫光食品集团股份有限公司",
    rNameEn: "",
    cover: ["/sheet11/饭扫光1.jpg", "/sheet11/饭扫光2.png", "/sheet11/饭扫光3.jpg"],
    description:
      "四川饭扫光食品集团股份有限公司自2000年3月诞生之日起，便以研发、生产与销售饭扫光®下饭菜、川老汇®郫县豆瓣及复合调味料为核心。历经二十多年的风雨洗礼与不懈努力，公司不仅逐步构建起一个完善的产业生态布局，更跃升为川味下饭菜和川味调味品市场的领军企业。",
    descriptionEn: "",
    feature:
      "饭扫光发源于郫都，是以野香菌、野竹笋、金针菇等丰富食材作为原材料，采用传统泡椒炒制工艺而成，被称为川菜的一张名片。",
    featureEn: "",
    address: "安德街道",
    addressEn: "",
    phone: "15102854563",
    longLatitude: "",
    longLatitudeId: "B0FFHFCDXQ",
    left: 595,
    top: 285,
    width: 80,
    height: 55,
  },
  {
    id: "四川友联味业食品有限公司",
    rName: "四川友联味业食品有限公司",
    rNameEn: "",
    cover: [
      "/sheet11/友联味业4.jpg",
      "/sheet11/友联味业1.jpg",
      "/sheet11/友联味业2.jpg",
      "/sheet11/友联味业3.jpg",
      "/sheet11/友联味业5.jpg",
    ],
    description:
      "四川友联味业食品有限公司始创于1993年，厂区分别位于成都市郫都区中国川菜产业园区，是四川省内研发、生产、销售川菜调味品的龙头企业之一。公司在继承传统工艺精髓基础上，勇于探索调味料结合现代智造科技之路，多项产品成为行业首创。生产管理过程中，引入ISO9001:2000、HACCP、ISO 22000等质量管理体系，企业先后被评为“省级企业技术中心”“国家高新技术企业”“全国民族用品定点生产企业”“中华人民共和国进出口企业”“省级农业产业化经营重点龙头企业”“省级专精特新企业”“省级老字号”等称号。企业品牌 “友联”“川骄”先后荣获“四川省著名商标”“四川省名牌产品”荣誉，产品畅销全国。",
    descriptionEn: "",
    feature:
      "四川友联味业食品有限公司于1993年组建,年销售额近亿元,是四川省内致力于开发、生产、销售四川调味品的龙头企业之一。",
    featureEn: "",
    address: "安德街道",
    addressEn: "",
    phone: "13678117735",
    longLatitude: "",
    longLatitudeId: "B001C09VLT",
    left: 1085,
    top: 555,
    width: 74,
    height: 55,
  },
  {
    id: "旺丰牌豆瓣",
    rName: "旺丰牌豆瓣",
    rNameEn: "",
    cover: ["/sheet11/旺丰食品1.jpg", "/sheet11/旺丰食品2.jpg"],
    description:
      "公司一直秉示弘扬传示地方特色产业，以匠者之心传承和坚守300年产业工艺，培养了50余位郫县豆瓣专业技术人才。建立了避味问道、滋味匠心，遗香园等三大郫县豆瓣非物质文化传统手工酿晒场，同时也采用先进的科研技术和现代化生产方式不断创新传统工艺，建成2条2000m二十万級食品标准无尘车间.8条先进智能化生产线，建成原料精选全自动设备，生产工艺大数据采集系统、高速成像对标系统MBB人工智能码垛系统、多层自动化仓库等先进工艺设备，标准数字数据化全程监控引领推动产业发展将实现品规、品质、品效、品需的多维赋能。倾力打造“川菜之观”健康品牌形象",
    descriptionEn: "",
    feature:
      "成立于1988年，是集原材料种植，郫县豆瓣及系列调味品研发、专业生产、品牌营销为一体的郫县豆瓣品牌。",
    featureEn: "",
    address: "团结街道",
    addressEn: "",
    phone: "87951916",
    longLatitude: "",
    longLatitudeId: "B001C8VDX0",
    left: 1185,
    top: 480,
    width: 94,
    height: 50,
  },
  {
    id: "四川省丹丹郫县豆瓣集团股份有限公司",
    rName: "四川省丹丹郫县豆瓣集团股份有限公司",
    rNameEn: "",
    cover: ["/sheet11/丹丹豆瓣2.png", "/sheet11/丹丹豆瓣1.png"],
    description:
      "四川省丹丹郫县豆瓣集团股份有限公司成立于2003年。总部位于享有“川菜之乡”美誉的成都市郫都区中国川菜产业化园区，是一家集原料种植、“郫县豆瓣”及川菜调味料研发、专业生产、品牌营销为一体的农业产业化国家重点龙头企业。。丹丹在传承郫县豆瓣三百多年传统技艺的基础上，依托“国家豆瓣酱加工技术研发专业中心”“食品用酶生物技术国家和地方联合工程研究中心”等技术创新平台。建立了引领行业高质量发展的“绿色智能制造”和“产学研用”体系，倾力打造“川菜之魂”健康品牌形象。",
    descriptionEn: "",
    feature:
      "四川省丹丹郫县豆瓣集团股份有限公司成立于2003年，总部位于成都市郫都区中国川菜产业化园区，是一家农业产业化国家重点龙头企业。",
    featureEn: "",
    address: "安德街道",
    addressEn: "",
    phone: "028-87868160",
    longLatitude: "",
    longLatitudeId: "B0FFH3WIF9",
    left: 485,
    top: 255,
    width: 59,
    height: 40,
  },
  {
    id: "四川省郫筒酱园有限责任公司",
    rName: "四川省郫筒酱园有限责任公司",
    rNameEn: "",
    cover: ["/sheet11/郫筒酱园.jpg"],
    description:
      "四川省郫筒酱园有限责任公司是一家秉承“做好人，做好产品”为核心理念的现代化调味品生产企业，专注传承与创新郫县豆瓣这一川菜之魂。公司扎根于中国“川菜之乡”——成都郫都区的中国川菜产业城，依托百年工艺积淀与科技创新，致力于为消费者提供高品质、健康安全的传统发酵调味品。",
    descriptionEn: "",
    feature:
      "位于郫都城灌路96号。公司生产的豆瓣、酱油、醋等系列产品，是精选用优质鲜椒、蚕豆、黄豆、小麦为原料，采用传统与现代相结合的方法，精工酿造而成。",
    featureEn: "",
    address: "安德街道",
    addressEn: "",
    phone: "13708037325",
    longLatitude: "",
    longLatitudeId: "B001C8U8H4",
    left: 480,
    top: 355,
    width: 69,
    height: 55,
  },
];
