<template>
  <div class="wrraper">
    <div>
      <img src="@/assets/storeList/back.png" @click="goHome" class="back-icon" />
    </div>
    <div class="store-list-content">
      <!-- 英文情况下，没有nameEn的不展示 -->
      <div
        class="store-list"
        v-for="(store, storeIndex) in currentTypeData"
        v-show="(isEnglish && store.nameEn) || !isEnglish"
        :key="storeIndex"
      >
        <img src="@/assets/storeList/box_head.png" class="box-head" />
        <div class="content sjmc" v-if="storeList.includes(navType)">
          <li class="title">
            <span>{{ isEnglish ? store.nameEn : store.name }}</span>
          </li>
          <!-- 英文情况下，没有rNameEn的不展示 -->
          <div class="dish-content" v-for="(item, index) in store.data" :key="index">
            <div class="dish-name" @click="navType !== '市级名菜' ? goMap(item) : ''">
              <img src="@/assets/storeList/border_1.png" class="border-img border-img-1" />
              <img src="@/assets/storeList/border_2.png" class="border-img border-img-2" />
              <div class="name">
                {{ isEnglish ? item.dishNameEn || item.rNameEn : item.dishName || item.rName }}
              </div>
              <div class="img-des" :class="isEnglish ? '' : 'is-chinese'">
                <img :src="BASE_URL + (item.opacityCover || item.cover[0])" class="opacity-cover" />
                <div class="des">
                  {{
                    isEnglish
                      ? item.featureEn || item.descriptionEn
                      : item.feature || item.description
                  }}
                </div>
              </div>
            </div>
            <div class="store-list" v-if="navType === '市级名菜'">
              <div class="store-title">
                {{ isEnglish ? "Recommended Restaurant：" : "推荐餐厅：" }}
              </div>
              <p
                v-for="(dish, dishIndex) in item.restaurants"
                :key="dishIndex"
                @click="goMap(dish)"
              >
                {{ isEnglish ? dish.rNameEn : dish.rName }}
              </p>
            </div>
          </div>
        </div>
        <div class="content douban" v-else-if="navType === '郫县豆瓣宴'">
          <li class="title">
            <span>{{ isEnglish ? store.nameEn : store.name }}</span>
          </li>
          <div class="head-des">
            {{ isEnglish ? store.data[0].descriptionEn : store.data[0].description }}
          </div>
          <div class="dish-list">
            <div class="dish-content" v-for="(item, index) in store.data" :key="index">
              <div class="dish-name" :class="isEnglish ? 'is-english' : ''">
                {{ index > 8 ? index + 1 : "0" + (index + 1) }}
                {{ isEnglish ? item.dishNameEn : item.dishName }}
              </div>
              <div class="img-content">
                <img :src="BASE_URL + item.cover" class="dish-img" />
              </div>
            </div>
          </div>
          <div class="store-title">{{ isEnglish ? "Recommended Restaurant：" : "推荐餐厅：" }}</div>
          <p
            v-for="(dish, dishIndex) in store.storeList"
            :key="dishIndex"
            @click="goMap(dish)"
            class="douban-store"
          >
            {{ isEnglish ? dish.rNameEn : dish.rName }}
          </p>
        </div>
        <div class="content" v-else>
          <li class="title">
            <span>{{ isEnglish ? store.nameEn : store.name }}</span>
          </li>
          <p v-for="(item, index) in store.data" :key="index" @click="dialogPop(item)">
            {{ isEnglish ? item.rNameEn : item.rName }}
          </p>
        </div>
      </div>
    </div>
    <van-popup v-model="isShowDialog" :style="{ 'background-color': 'transparent' }">
      <DetailDialog :show="isShowDialog" @goBack="close" :detail="detail" :isEnglish="isEnglish" />
    </van-popup>
  </div>
</template>
<script>
import DetailDialog from "./DetailDialog.vue";
import { navMap } from "@/const/allMap";
import { BASE_URL } from "@/const/const";

export default {
  name: "StoreList",
  props: {
    navType: {
      type: String,
      default: "导航",
    },
    isEnglish: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DetailDialog,
  },
  data() {
    return {
      BASE_URL,
      storeList: ["市级名菜", "重点消费场景", "美食聚集区", "四川老字号"],
      detail: {},
      isShowDialog: false,
    };
  },
  computed: {
    currentTypeData() {
      return navMap[this.navType];
    },
  },
  methods: {
    goHome() {
      this.$emit("goHome");
    },
    dialogPop(item) {
      this.detail = item;
      this.isShowDialog = true;
    },
    goMap(item) {
      window.open(`https://www.gaode.com/place/${item.longLatitudeId}`);
    },
    close() {
      this.isShowDialog = false;
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.wrraper {
  background-image: url(~@/assets/home/bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 20rem;
  box-sizing: border-box;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 12;
  .back-icon {
    width: 23rem;
    height: 18rem;
    margin-bottom: 18rem;
    margin-top: 3rem;
  }
  .switch {
    position: fixed;
    top: 24rem;
    right: 22rem;
    width: 22rem;
    z-index: 11;
  }

  .box-head {
    width: 100%;
  }

  .store-list-content {
    height: calc(100vh - 50rem);
    overflow-y: scroll;
  }
  .content {
    position: relative;
    margin: 0 2rem;
    background-image: linear-gradient(0deg, rgba(0, 194, 255, 0.28) 0%, rgba(0, 161, 252, 0) 100%);
    padding: 15rem;
    margin-bottom: 35rem;
    .store-title {
      font-size: 15rem;
      color: #61fffd;
      font-weight: 600;
      padding: 10rem 0;
    }
    &.sjmc {
      padding: 15rem 0;
      background-image: linear-gradient(0deg, rgba(0, 194, 255, 0) 0%, rgba(0, 161, 252, 0) 100%);
      .dish-content {
        padding-bottom: 30rem;
      }
      p {
        line-height: 12rem;
        padding-bottom: 18rem;
        padding-left: 15rem;
      }
      p:nth-child(6n) {
        margin-bottom: 0;
      }
      .store-title {
        padding-left: 15rem;
      }
      .dish-name {
        width: 330rem;
        box-sizing: border-box;
        background-image: linear-gradient(
          0deg,
          rgba(0, 194, 255, 0.28) 0%,
          rgba(0, 161, 252, 0) 100%
        );
        border: 0.6px solid rgba(174, 213, 252, 1);
        border-radius: 12rem;
        padding: 15rem 15rem 10rem;
        position: relative;
        .border-img {
          position: absolute;
          &.border-img-1 {
            width: 143rem;
            left: 51rem;
            top: -3rem;
          }
          &.border-img-2 {
            width: 5rem;
            right: 0;
            bottom: 13rem;
          }
        }
        .name {
          padding-left: 4rem;
          padding-bottom: 6rem;
        }
        &::before {
          left: 15rem;
          top: 21rem;
        }
        .img-des {
          min-height: 76rem;
          font-size: 12rem;
          color: #caf4ff;
          line-height: 21rem;
          &.is-chinese {
            display: flex;
            align-items: center;
          }
          .des {
            flex: 1;
          }
          .opacity-cover {
            width: 122rem;
            height: 76rem;
            border-radius: 4rem;
            margin-right: 12rem;
          }
        }
      }
    }
    &.douban {
      .head-des {
        font-size: 12rem;
        color: #caf4ff;
        line-height: 21rem;
        padding-bottom: 12rem;
      }
      .dish-list {
        display: flex;
        flex-wrap: wrap;
        .dish-content {
          width: 145rem;
          &:nth-child(2n - 1) {
            margin-right: 10rem;
          }
          .img-content {
            width: 145rem;
            height: 100rem;
            .dish-img {
              width: 100%;
              height: 100%;
            }
          }
          .dish-name {
            font-size: 12rem;
            word-break: break-all;
            text-overflow: justify;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            padding-bottom: 10rem;
            &.is-english {
              height: 34rem;
            }
          }
        }
      }
      p {
        line-height: 12rem;
        padding-bottom: 18rem;
      }
      p:nth-child(6n) {
        margin-bottom: 0;
      }
      .douban-store:nth-child(6n - 2) {
        margin-bottom: 25rem;
      }
    }
    p {
      font-family: PingFang-SC-Medium;
      font-size: 14rem;
      line-height: 30rem;
      color: #caf4ff;
      text-decoration: underline;
    }

    p:nth-child(6n) {
      margin-bottom: 25rem;
    }

    .title {
      font-family: ZiZhiQuXiMaiTi;
      font-size: 15rem;
      color: #61fffd;
      font-weight: 600;
      position: absolute;
      top: -26rem;
      left: 15rem;
    }
    .dish-content {
      padding-bottom: 20rem;
    }
    .jinchai {
      padding: 15rem 0;
      .dish-name {
        width: 335rem;
        background-image: linear-gradient(
          0deg,
          rgba(0, 194, 255, 0.28) 0%,
          rgba(0, 161, 252, 0) 100%
        );
        border: 0.6px solid rgba(174, 213, 252, 1);
        border-radius: 12rem;
      }
    }
    .dish-name {
      font-size: 13rem;
      color: #fff;
      position: relative;
      padding-left: 6rem;
      line-height: 24rem;
      padding-bottom: 4rem;
      &::before {
        content: "";
        position: absolute;
        width: 2rem;
        height: 12rem;
        background: #61fffd;
        left: 0;
        top: 6rem;
      }
    }
  }
}
</style>
