export const sheet4 = [
  {
    id: "郫县豆瓣鱼",
    dishName: "郫县豆瓣鱼",
    dishNameEn: "Douban-Flavored (The spicy bean paste) Fish",
    left: 519,
    top: 420,
    width: 71,
    height: 57,
    cover: ["/sheet4/1-0-1.jpg"], // 菜品列表图
    opacityCover: "/sheet4/1-0-1-0.png", // 透明图片
    description:
      "本菜为四川地区特色传统名菜，是郫县豆瓣与魚的美味融合，成品色泽红亮、肉质细嫩、豆瓣味浓郁芳香、味鲜可口。",
    descriptionEn:
      `This dish is a traditional famous dish with Sichuan characteristics, which combines Pixian Douban (spicy bean paste from Pixian County) and fish. The finished dish has a bright red color, delicate and tender meat, a rich and fragrant flavor of Douban, and a delicious taste.`,
    restaurants: [
      {
        rName: "印象泰和园",
        rNameEn: "Yinxiang. Taihe Garden",
        address: "成都市郫都区友爱镇升平村266号",
        addressEn: "No. 266 Shengping Village, Youai Town, Pidu District, Chengdu",
        phone: "028-87883777",
        tel: "028-87883777",
        longLatitude: "103.85,30.82",
        longLatitudeId: "B0HU1CBRJY",
        cover: "/sheet1/11-1-1.jpg", // 特色餐厅第一张封面图，去表123里面找；
      },
      {
        rName: "西御园乡村酒店",
        rNameEn: "Xiyuyuan Country Hotel",
        cover: "/sheet1/3-1-1.jpg",
        address: "成都市郫都区郫筒街道洪石村三组309号",
        addressEn: "No. 309, Group 3, Hongshi Village, Pitong Street, Pidu District, Chengdu",
        phone: "028—675178889",
        tel: "028—67517888",
        longLatitude: "103.87,30.83",
        longLatitudeId: "B001C7VM1M",
      },
      {
        rName: "郫都区容和苑酒店",
        rNameEn: "Rongheyuan Hotel",
        cover: "/sheet4/1-0-1-1.jpg",
        address: "成都市郫都区现代工业港南区滨清路452号",
        addressEn:
          "No. 452, Binqing Road, South Area of Modern Industrial Port, Pixiandu District, Chengdu",
        phone: "028-87881186",
        tel: "028-87881186",
        longLatitude: "103.88,30.77",
        longLatitudeId: "B0FFFW25OD",
      },
      {
        rName: "徐记尚河渔庄",
        rNameEn: "Xu's Shanghe Fishing Village",
        cover: "/sheet1/17-1-1.jpg",
        address: "成都市郫都区三道堰镇水梨村1组",
        addressEn: " Group 1, Shuili Village, Sandaoyan Town, Pidu District, Chengdu",
        phone: "13568842353",
        tel: "028-87910353",
        longLatitude: "103.93,30.88",
        longLatitudeId: "B0FFGSF5SE",
      },
    ],
  },
  {
    id: "周鹅油烫鹅",
    dishName: "周鹅油烫鹅",
    dishNameEn: "Chow’s Fried Goose",
    left: 751,
    top: 396,
    width: 48,
    height: 33,
    cover: ["/sheet4/2-0-1.jpg"],
    opacityCover: "/sheet4/1-0-2-0.png",
    description:
      "菜品具有50年历史，其色汁润红亮、其质舒和化渣，其味老卤饱汁、天然本鲜、咸淡均匀、口味悠长。",
    descriptionEn:
      `This dish has a history of 50 years. It has a bright red color, a smooth and tender texture, and can dissolve easily in the mouth. Its flavor is rich and natural, with a balanced saltiness and a long-lasting taste.`,
    restaurants: [
      {
        rName: "周鹅腌卤店",
        rNameEn: "Chow’s Fried Goose",
        cover: "/sheet2/5-1-1.jpg",
        address: "成都市郫都区郫简镇南大街真武巷34—36号",
        addressEn: "No. 34-36 Zhenwu Lane, South Street, Pijian Town, Pidu District, Chengdu",
        phone: "18180655003",
        tel: "18180655003",
        longLatitude: "103.88,30.81",
        longLatitudeId: "B001C91TR3",
      },
    ],
  },
  {
    id: "犀浦鲢鱼",
    dishName: "犀浦鲢鱼",
    dishNameEn: "Xipu Lianyu (Silver Carp)",
    // 底图上删除点位，但是数据保留
    // left: 1131,
    // top: 732,
    // width: 63,
    // height: 57,
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    cover: ["/sheet4/3-0-1.jpg"],
    opacityCover: "/sheet4/1-0-3-0.png",
    description:
      "菜品被载入《中国菜谱》和《川菜烹饪事典》，至今有百余年历史，菜品颜色红亮、咸甜香辣、鱼肉软嫩、香鲜味美。",
    descriptionEn:
      `This dish is recorded in "Chinese Cookbook" and "Dictionary of Sichuan Cuisine", with a history of over a hundred years. The dish has a bright red color, a savory and spicy taste, and the fish meat is tender and delicious.`,
    restaurants: [
      {
        rName: "天房阁",
        rNameEn: "Tianfangge Restaurant",
        cover: "/sheet1/9-1-1.jpg",
        address: "成都市郫都区犀浦镇围城南路588号",
        addressEn: "No.588, South Weicheng Road, Xipu Town, Pidu District, Chengdu",
        phone: "028-87856777",
        tel: "028-87856777",
        longLatitude: "103.96,30.75",
        longLatitudeId: "B001C8OAZU",
      },
    ],
  },
  {
    id: "玉笼轩芙蓉包",
    dishName: "玉笼轩芙蓉包",
    dishNameEn: "Yulongxuan Furong Stuffed Bun",
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    cover: ["/sheet4/4-0-1.jpg"],
    opacityCover: "/sheet4/1-0-4-0.png",
    description:
      "菜品制作工艺传承于宫廷御厨，具有皮薄光滑、馅多鲜美、味汁浓郁、回味无穷的特点，其质淳朴健康，口感清新自然。",
    descriptionEn:
      `The cooking technique of this dish has been passed down from the imperial court chef, and it features thin and smooth skin, abundant and delicious filling, rich and flavorful sauce, and an endless aftertaste. The dish is simple, healthy, and has a fresh and natural taste.`,
    restaurants: [
      {
        rName: "玉笼轩芙蓉包",
        rNameEn: "Yulongxuan Furong Stuffed Bun",
        cover: "/sheet2/8-1-1.jpg",
        address: "成都市郫都区安德街道安平东路16号1幢1层13号",
        addressEn:
          "  Room 13, 1st Floor, Building 1,  No.16, East Anping Road, Ande Street, Pidu District, Chengdu",
        phone: "13668252110",
        tel: "13668252110",
        longLatitude: "103.81,30.86",
        longLatitudeId: "B0FFFQ1YYQ",
      },
    ],
  },
  {
    id: "杨鸡肉",
    dishName: "杨鸡肉",
    dishNameEn: "Yang's Chicken",
    left: 633,
    top: 414,
    width: 52,
    height: 48,
    cover: ["/sheet4/5-0-1.jpg"],
    opacityCover: "/sheet4/1-0-5-0.png",
    description: "菜品选用农家黄皮跑山仔鸡公作为主料，红味麻辣鲜香，白味咸鲜味宜，入口味纯化渣。",
    descriptionEn:
      `This dish uses locally-raised free-range roosters as the main ingredient, with a spicy and flavorful taste that is both numbing and refreshing. The dish has a balanced saltiness, and the meat is tender and easily dissolves in the mouth.`,
    restaurants: [
      {
        rName: "杨鸡肉陌上人家喜宴荟休闲庄",
        rNameEn: "Yang‘s Chicken Moshangrenjia XiyanHui Leisure Village",
        cover: "/sheet1/12-1-1.jpg",
        address: "成都市郫都区友爱镇升平村7组207号",
        addressEn: "No.207, Group 7, Shengping Village, Youai Town，Pidu District, Chengdu",
        phone: "028-87928858",
        tel: "028-87928858",
        longLatitude: "103.85,30.82",
        longLatitudeId: "B001C80VFO",
      },
      {
        rName: "杨鸡肉川味寻香体验店",
        rNameEn: "Yang's Chicken Sichuan Flavor Seeking Fragrance Experience Store",
        cover: "/sheet2/4-1-1.jpg",
        address: "成都市郫都区郫筒街道望丛中路492-498号",
        addressEn: "No.492-498, Middle Wangcong Road, Pitong Street.Pidu District, Chengdu",
        phone: "028-87923651",
        tel: "028-87923651",
        longLatitude: "103.88,30.80",
        longLatitudeId: "B001C0287D",
      },
      {
        rName: "杨鸡肉（红高店）",
        rNameEn: "Yang's Chicken (Honggao Subbranch)",
        cover: "/sheet4/5-0-1-1.jpg",
        address: "成都市郫都区红光街道银润路463-467号",
        addressEn: "No.463-467, Yinrun Road, Hongguang Street,Pidu District, Chengdu",
        phone: "028-81055015",
        tel: "028-81055015",
        longLatitude: "103.94,30.77",
        longLatitudeId: "B0HBO5M47O",
      },
    ],
  },
  {
    id: "沱砣鱼",
    dishName: "沱砣鱼",
    dishNameEn: "Tuo Tuo Fish",
    left: 603,
    top: 351,
    width: 75,
    height: 63,
    cover: ["/sheet4/6-0-1.jpg"],
    opacityCover: "/sheet4/1-0-6-0.png",
    description:
      "菜品色泽红亮、麻辣鲜香、鱼肉细嫩、豆腐滑爽、浓香扑鼻，曾被评为中国国际旅游美食之旅最受欢迎菜品、中国川味金骄奖-川味中国˙百菜百味榜”金榜菜品等。",
    descriptionEn:
      `This dish has a bright red color, a spicy and fresh flavor, delicate and tender fish meat, silky smooth tofu, and a strong and fragrant aroma. It has been awarded as the most popular dish in China's International Tourism Food Tour and the Gold Medal of Sichuan Flavor in the "100 Dishes, 100 Flavors of Sichuan, China" list.`,
    restaurants: [
      {
        rName: "红星饭店",
        rNameEn: "Red Star Hotel",
        cover: "/sheet1/1-1-1.jpg",
        address: "成都市郫都区郫筒街道梨园村5组203号",
        addressEn: " No.203, Group 5, Liyuan Village, Pitong Street, Pidu District, Chengdu",
        phone: "028－87884696",
        tel: "028－87884696",
        longLatitude: "103.86,30.83",
        longLatitudeId: "B001C804PK",
      },
    ],
  },
  {
    id: "唐昌苏鸭子板鸭",
    dishName: "唐昌苏鸭子板鸭",
    dishNameEn: "Tangchang Su’s Duck",
    left: 483,
    top: 27,
    width: 105,
    height: 99,
    cover: ["/sheet4/7-0-1.jpg"],
    opacityCover: "/sheet4/1-0-7-0.png",
    description:
      "菜品选料精良，配方考究，成品型如琵琶、挽如玉盘、色鲜味香、肥而不腻、离骨化渣、咸淡均匀。",
    descriptionEn:
      `This dish is made with carefully selected ingredients and a meticulously crafted recipe. The finished product is shaped like a pipa (a musicalinstrument), arranged like a jade plate, with a fresh and fragrant taste,rich but not greasy, and the meat easily separates from the bones and dissolves in the mouth. The dish has a balanced saltiness throughout.`,
    restaurants: [
      {
        rName: "唐昌苏鸭子",
        rNameEn: "Tangchang Su’s Duck",
        cover: "/sheet4/7-0-1-1.jpg",
        address: "成都市郫都区唐昌镇北外街105号",
        addressEn:
          "No.105, Beiwai Street, Tangchang Town, Pidu District, Chengdu",
        phone: "13548096630",
        tel: {
          Headquarters: 13548096630,
          "Hongwa Store": 13981776417,
          "South Gate Store": 13548129225,
        },
        longLatitude: "103.83,30.93",
        longLatitudeId: "B0G29RPOD3",
      },
    ],
  },
  {
    id: "苕菜狮子头",
    dishName: "苕菜狮子头",
    dishNameEn: "Stewed Wild Vetch Meatballs",
    left: 690,
    top: 351,
    width: 48,
    height: 48,
    cover: ["/sheet4/8-0-1.jpg"],
    opacityCover: "/sheet4/1-0-8-0.png",
    description:
      "菜品由中国川菜名师张荣兴大师根据苏州狮子头改创而成，成菜外观靓丽、苕菜清鲜、肉质细嫩、清鲜醇正、回味悠长。",
    descriptionEn:
      `This dish was created by Master Zhang Rongxing, a famous Sichuan cuisine chef, based on the traditional Suzhou-style Big meatball. The finished dish has an attractive appearance, with fresh and crisp wild vetch (a type of vegetable), delicate and tender meat, a clear and pure flavor, and a long-lasting aftertaste.`,
    restaurants: [
      {
        rName: "西御园乡村酒店",
        rNameEn: "Xiyuyuan Country Hotel",
        cover: "/sheet1/3-1-1.jpg",
        address: "成都市郫都区郫筒街道洪石村三组309号",
        addressEn: "No. 309, Group 3, Hongshi Village, Pitong Street, Pidu District, Chengdu",
        phone: "028—67517888",
        tel: "028—67517889",
        longLatitude: "103.87,30.83",
        longLatitudeId: "B001C7VM1M",
      },
    ],
  },
  {
    id: "火鞭牛肉",
    dishName: "火鞭牛肉",
    dishNameEn: "Huobian Beef",
    left: 669,
    top: 696,
    width: 51,
    height: 48,
    cover: ["/sheet4/9-0-1.jpg"],
    opacityCover: "/sheet4/1-0-9-0.png",
    description:
      "菜品选用三年以上新鲜黄牛腿肉改刀、腌制、风干烟熏而成，形似鞭炮、色泽红亮、香气浓郁、软硬适度、入口化渣。",
    descriptionEn:
      `This dish is made by cutting, marinating, air-drying, and smoking fresh yellow beef leg meat aged for over three years. The finished dish has a shape similar to a firecracker, a bright red color, a strong aroma,and a moderate texture that easily dissolves in the mouth.`,
    restaurants: [
      {
        rName: "铜壶苑鑫苑",
        rNameEn: "Tonghuyuan Xinyuan Fish Restaurant",
        cover: "/sheet1/16-1-1.jpg",
        address: "成都市郫都区现代工业港南区滨清路4号",
        addressEn: " No. 4, Binqing Road, South Area of Modern Industrial Port, Pidu District, Chengdu",
        phone: "13438866525",
        tel: "028-87876333",
        longLatitude: "103.88,30.77",
        longLatitudeId: "B0FFF53EFV",
      },
    ],
  },
  {
    id: "五香猪肝",
    dishName: "五香猪肝",
    dishNameEn: "Spiced Pork Liver",
    left: 843,
    top: 471,
    width: 54,
    height: 39,
    cover: ["/sheet4/10-0-1.jpg"],
    opacityCover: "/sheet4/1-0-10-0.png",
    description:
      "菜品曾获成都市名小吃称号，成品口感干香滋润、入口化渣，是郫都众多家庭节假日聚会配菜首选。",
    descriptionEn:
      `This dish has been awarded the title of "Famous Snack of Chengdu City" and is a popular choice for family gatherings and holidays in Pidu district. The finished dish has a dry and fragrant texture and easily dissolves in the mouth, making it a favored accompaniment for such occasions.`,
    restaurants: [
      {
        rName: "余记腌卤",
        rNameEn: "Yu's Braised Dishes",
        cover: "/sheet4/10-0-1-1.jpg",
        address: "成都市郫都区郫筒街道南大街210号",
        addressEn: "No.210, South Street, Pitong Street, Pidu District, Chengdu",
        phone: "13980822650",
        tel: "13980822650",
        longLatitude: "103.88,30.81",
        longLatitudeId: "B0FFG85FMC",
      },
    ],
  },
  {
    id: "豆瓣藿香鳝片",
    dishName: "豆瓣藿香鳝片",
    dishNameEn: "Douban-Flavored Rice Eel Slices with Agastache Rugosa",
    left: 834,
    top: 333,
    width: 38,
    height: 57,
    cover: ["/sheet4/11-0-1.jpg"],
    opacityCover: "/sheet4/1-0-11-0.png",
    description:
      "菜品主食材取自稻田黄鳝，经过精心烹饪制作，成品色泽红亮、家常味浓、藿香四溢、鳝片软糯入味。",
    descriptionEn:
      `The main ingredient of this dish is yellow eel from the rice fields, which is carefully cooked and prepared to produce a finished dish with a bright red color, rich homestyle flavor, and a strong aroma of patchouli. The eel slices are soft, glutinous and full of flavor.`,
    restaurants: [
      {
        rName: "景水苑",
        rNameEn: "Jingshuiyuan Garden Restaurant",
        cover: "/sheet1/4-1-1.jpg",
        address: "成都市郫都区郫筒街道成灌西路803号附1号",
        addressEn: "Attached No 1 of No.803, West Chengguan  Road, Pitong Street, Pidu District, Chengdu",
        phone: "18180227828",
        tel: "18180227828",
        longLatitude: "103.89,30.81",
        longLatitudeId: "B001C95GD6",
      },
    ],
  },
  {
    id: "回锅鱼",
    dishName: "回锅鱼",
    dishNameEn: "Twice-cooked fish",
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    cover: ["/sheet4/12-0-1.jpg"],
    opacityCover: "/sheet4/1-0-12-0.png",
    description:
      "菜品根据回锅肉演变而来，通过腌制、挂糊、软炸、炒制等手法，融入郫县豆瓣，使菜品色泽红亮、外酥内嫩、咸鲜微辣，是一道老少皆宜的菜品佳肴。",
    descriptionEn:
      `The dish evolved from twice-cooked pork and is prepared by marinating, coating, deep-frying, and stir-frying with Pixian douban, resulting in a dish with a bright red color, crispy exterior, tender interior, and a slightly salty and spicy taste. It is a dish that appeals to both young and old.`,
    restaurants: [
      {
        rName: "郫都区容和苑酒店",
        rNameEn: "Rongheyuan Hotel",
        cover: "/sheet4/1-0-1-1.jpg",
        address: "成都市郫都区现代工业港南区滨清路452号",
        addressEn:
          " No.452, Binqing Road, South Area of Modern Industrial Port, Pidu District, Chengdu.",
        phone: "028-87881186",
        tel: "028-87881186",
        longLatitude: "103.88,30.77",
        longLatitudeId: "B0FFFW25OD",
      },
    ],
  },
];
