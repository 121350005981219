export const sheet1 = [
  {
    id: "红星饭店",
    belong: "郫筒街道",
    belongEn: "Pitong Street",
    rName: "红星饭店",
    rNameEn: "Red Star Hotel",
    cover: ["/sheet1/1-1-1.jpg"], // 封面图列表
    description:
      "红星饭店1994年6月18日开业至今，从40平米、4名员工、4张桌子，发展到如今近60亩的大型餐饮，已成为郫都区地标之一。创办人高德均凭借其身上的“工匠精神”，支撑红星持续闪亮至今，红星饭店凭借其味道传承29年经久不衰。",
    descriptionEn:
      'Since its opening on June 18, 1994, Red Star Hotel has grown from a 40-square-meter establishment with four tables and four employees to a nearly 60-acre, large-scale restaurant, becoming a landmark in Pidu District. Founded on the "craftsman spirit" of its creator, Gao Dejun, Red Star has continued to shine for nearly three decades. Thanks to its delicious cuisine, Red Star has remained popular and enduring throughout its 29-year history.',
    dishs: [
      {
        dishName: "沱砣鱼系列",
        dishNameEn: "Tuo Tuo Fish Series cuisine",
        cover: "/sheet1/1-0-1.jpg", // 菜品封面图地址
        description:
          "“沱”，指菜品选用的鱼为来自沱江里的优质花鲢；“砣”，指菜品中鱼肉的形状为“块状”。沱砣鱼是红星饭店糅合了太安鱼、球溪河鲢鱼的烹饪特点，再结合老成都火锅的麻辣烫料，独创的一种烹鱼方法，成品鱼肉细嫩、豆腐滑爽、色泽红亮、麻辣鲜香、浓香扑鼻。",
        descriptionEn: `"TuoTuo" refers to the high-quality crucian carp sourced from the Tuo River, while "tuo" refers to the shape of the fish meat in chunks. The Tuoluo Fish dish combines the cooking characteristics of Tai'an fish and Qiuxi River crucian carp with the spicy hot pot seasoning of old Chengdu, creating a unique method of cooking fish. The finished dish features tender and delicate fish meat, smooth tofu, bright red color, spicy and fresh flavor, and a strong and fragrant aroma.`,
      },
    ],
    address: "成都市郫都区郫筒街道梨园村5组203号",
    addressEn: "No.203, Group 5, Liyuan Village, Pitong Street, Pidu district, Chengdu",
    phone: "028-87884296",
    tel: "028-87884296",
    longLatitude: "103.86,30.83",
    longLatitudeId: "B001C804PK",
    left: 100000,
    top: 100000,
    width: 100000,
    height: 100000,
  },

  {
    id: "汇鑫苑",
    belong: "郫筒街道",
    belongEn: "Pitong Street",
    rName: "汇鑫苑",
    rNameEn: "Huixinyuan Farmhouse",
    cover: ["/sheet1/2-1-1.jpg"], // 封面图列表
    description:
      "郫都区汇鑫苑休闲庄成立于 2008年1月3日，占地30余亩，是一家集餐饮、茶坊、棋牌、品茗、会议、草坪婚礼、儿童乐园等为一体的多功能绿色环保型生态休闲庄，可承接婚宴、寿宴、生日宴、满月宴、乔迁宴、商务洽淡、亲朋聚会等各种宴席，能够同时容纳 1500 人左右就餐。欧式的布局，绿色浪漫的情调，清新洁净的空气，予以顾客“奢华、淡雅、舒适、浪漫”的体验。",
    descriptionEn: `Huixinyuan Farmhouse, located in Pitong Street, Pidu district, was established on January 3, 2008. Covering an area of over 30 acres, it is a multi-functional, green, and environmentally-friendly ecological leisure manor that combines dining, tea houses, chess and card rooms, tea tasting, conferences, lawn weddings, and children's playgrounds. It can accommodate various banquets, such as weddings, birthdays, business meetings, and family gatherings, with a capacity of around 1500 diners. The overall layout is in European style, with a green and romantic ambiance, fresh and clean air, providing customers with a luxurious, elegant, comfortable, and romantic experience. `,
    dishs: [
      {
        dishName: "汇鑫大盘鸡",
        dishNameEn: "Huixin Dapanji (big plate chicken)",
        cover: "/sheet1/2-0-1.jpg", // 菜品封面图地址
        description:
          "汇鑫大盘鸡，精选剑阁土鸡，成菜皮脆肉弹，回味麻辣鲜香，在汇鑫苑畅销15年，深受消费者喜爱。",
        descriptionEn: `Huixin Dapanji (big plate chicken) is a signature dish, made with selected Jiange local chickens, and has been popular for 15 years at Huixinyuan, loved by customers for its crispy skin, tender meat, and spicy and delicious flavor. `,
      },
    ],
    address: "成都市郫都区郫筒街道梨园村5组203号",
    addressEn: "No. 203, Group 5, Liyuan Village, Pitong Street, Pidu district, Chengdu",
    phone: "028-61070099",
    tel: "028-61070099",
    longLatitude: "103.86,30.83",
    longLatitudeId: "B0GUM65O2Q",
    left: 675,
    top: 462,
    width: 63,
    height: 60,
  },

  {
    id: "西御园乡村酒店",
    rName: "西御园乡村酒店",
    rNameEn: "Xiyuyuan Countryside Hotel",
    cover: ["/sheet1/3-1-1.jpg"],
    belong: "郫筒街道",
    belongEn: "Pitong Street",
    description:
      "西御园乡村酒店成立于2007年10月，拥有优秀的管理团队、技术精湛的厨师队伍及100余亩生态园林，配置数十桌机麻、棋牌、网球场、篮球场、乒乓室，可同时接待400人住宿、1000人用餐，是一家集餐饮、住宿、会议、娱乐为一体的综合型生态园林式四星级乡村酒店。",
    descriptionEn: `Xiyuyuan Countryside Hotel was founded in October 2007. It has an excellent management team, skilled chef team, and over 100 acres of ecological gardens. The hotel is equipped with dozens of mahjong tables, chess and card rooms, tennis courts, basketball courts, and ping pong rooms. It can accommodate up to 400 people for lodging and 1,000 people for dining. It is a comprehensive ecological garden-style four-star countryside hotel that integrates catering, lodging, conferences, and entertainment.`,
    dishs: [
      {
        dishName: "苕菜狮子头",
        dishNameEn: "Stewed Wild Vetch Meatballs",
        cover: "/sheet1/3-0-1.jpg",
        description:
          "苕菜狮子头为中国川菜名师张荣兴大师所创，根据苏州菜品狮子头对辅料进行改创，将猪肉剁细，做成圆饼状，先炸后焖并选用川西平原地区初春时蔬苕菜为辅料，淋于成菜上。改创后的成菜外观靓丽，苕菜清鲜，肉质细嫩，清鲜醇正，来上一口令人唇齿留香，回味悠长。",
        descriptionEn: `"Stewed Wild Vetch Meatballs" was created by Chinese Sichuan cuisine master Zhang Rongxing. Based on the traditional Suzhou-style dish "Lion's Head", the ingredients were modified. The pork was finely chopped and made into round cakes, which were first fried and then braised. The dish uses early spring vegetable shao cai from the western Sichuan plain as a supplement, and is poured over the finished dish. The modified dish has a beautiful appearance, with fresh and tender shao cai, and a savory and mellow taste that is fragrant and long-lasting on the palate.`,
      },
    ],
    address: "成都市郫都区老成灌路郫县客运中心北2公里",
    addressEn:
      "2 km north of Pixian Passenger Transport Center, Laocheng Guan Road, Pidu district, Chengdu City.",
    phone: "028—67517888",
    tel: "028—67517888",
    longLatitude: "103.86,30.83",
    longLatitudeId: "B001C7VM1M",
    left: 100000,
    top: 100000,
    width: 100000,
    height: 100000,
  },

  {
    id: "景水苑",
    rName: "景水苑",
    belong: "郫筒街道",
    belongEn: "Pitong Street",
    rNameEn: "Jingshuiyuan Garden Restaurant",
    cover: ["/sheet1/4-1-1.jpg"],
    description:
      "景水苑花园餐厅成立于2005年，占地30余亩，建筑与盆景相融合，风格传统而不失现代气息。餐厅配套2个停车场，400个停车位，可接待1500人同时用餐，菜品以特色川菜为主，麻、辣、鲜、香，色香味俱全。",
    descriptionEn: `Established in 2005, Jingshuiyuan Garden Restaurant covers an area of over 30 acres and blends its architecture with bonsai landscaping, combining traditional style with a modern touch. The restaurant boasts two parking lots with a total of 400 parking spaces and can accommodate up to 1500 diners at the same time. The menu mainly features distinctive Sichuan cuisine, with a perfect balance of spicy, fresh, and aromatic flavors.`,
    dishs: [
      {
        dishName: "藿香鳝鱼",
        dishNameEn: "Eel with Agastache Rugosa",
        cover: "/sheet1/4-0-1.jpg",
        description: "藿香鳝鱼成品色泽红亮、家常味浓、藿香四溢、鳝片软糯入味，极具地方特色。",
        descriptionEn: `The finished product has a bright red color, a rich and homey flavor, and the aroma of Houttuynia cordata, making it a dish with unique local characteristics.`,
      },
    ],
    address: "成都市郫都区郫筒镇成灌西路803号附1号",
    addressEn: "Attachted No 1 of No. 803, West Chengguan Road, Pidu District, Chengdu",
    phone: "18108214762",
    tel: "18108214762",
    longLatitude: "103.89,30.81",
    longLatitudeId: "B001C95GD6",
    left: 100000,
    top: 100000,
    width: 100000,
    height: 100000,
  },

  {
    id: "王府国宴",
    rName: "王府国宴",
    belong: "郫筒街道",
    belongEn: "Pitong Street",
    rNameEn: "Wong’s State Banquet Restaurant",
    cover: ["/sheet1/5-1-1.jpg"],
    description:
      "“王府国宴”是由成都市金屿实业有限公司投资、建设的五星级产业。营业面积3000平方米，可容纳1500人同时用餐，内设32间豪华包房以及同时容纳1000人就餐的四季盛宴、祥云宴会厅；餐厅主营燕、鲍、翅、生猛海鲜，致力打造成都城西五星级粤菜之巅、川菜之源。",
    descriptionEn: `"Wong’s State Banquet Restaurant" is a five-star industry invested and built by Chengdu Jinyu Industrial Co., Ltd. It has a business area of ​​3,000 square meters and can accommodate 1,500 people dining at the same time. It has 32 luxurious private rooms and the Four Seasons Feast and Xiangyun Banquet Hall, which can accommodate 1,000 diners at the same time. The restaurant specializes in bird's nest, abalone, shark fin, and fresh seafood, dedicated to creating the pinnacle of five-star Cantonese cuisine in the west of Chengdu and the origin of Sichuan cuisine.`,
    dishs: [
      {
        dishName: "松露汁明虾球",
        dishNameEn: "Truffle Sauce Crystal Shrimp",
        cover: "/sheet1/5-0-1.jpg",
        description:
          "第三届四川省金牌小吃。选用16--20青虾仁裹上自制的蛋白浆炸制外表金黄酥脆，在佐以大厨精心秘制的松露汁吃起来瞬间感觉味蕾受到极大的刺激与满足。此菜外表酥脆爽口，里面虾仁Q弹嫩滑。",
        descriptionEn:
          `Truffle Sauce Crystal Shrimp is the third Sichuan Province Gold Medal snack. Selecting 16-20 green shrimp and coating them with homemade egg white batter, the exterior is crispy and golden. With the chef's secret truffle sauce, it instantly stimulates and satisfies the taste buds. This dish has a crispy and refreshing exterior, and the shrimp inside is tender and smooth.`,
      },
    ],
    address: "成都市郫都区望丛中路1088号附二号",
    addressEn: "Attachted No 2 of No. 1088, Middle Wangcong Road, Pidu District, Chengdu",
    phone: "028-61778899",
    tel: "028-61778899",
    longLatitude: "103.90,30.79",
    longLatitudeId: "B0HUNAAR96",
    left: 822,
    top: 618,
    width: 66,
    height: 66,
  },

  {
    id: "蜀滋源",
    rName: "蜀滋源",
    belong: "郫筒街道",
    belongEn: "Pitong Street",
    rNameEn: "Shuziyuan Restaurant",
    cover: ["/sheet1/6-1-1.jpg", "/sheet1/6-1-2.jpg"],
    description:
      "蜀滋源酒家创办于2010年，营业面积约2000平方米，是集餐饮、茶坊为一体的用餐休闲场所。成立伊始，蜀滋源专注川菜制作与研发，努力提升服务技能，先后在菜品和服务技能比赛中均取得优异成绩。",
    descriptionEn:
      `Shuziyuan Restaurant was founded in 2010 with a business area of approximately 2000 square meters, offering a dining and leisure experience that integrates catering and tea. From the outset, Shuziyuan has focused on the development and production of Sichuan cuisine, striving to improve its service skills. It has achieved excellent results in both cuisine and service skills competitions.`,
    dishs: [
      {
        dishName: "高原红",
        dishNameEn: "Highland Red",
        cover: "/sheet1/6-0-1.jpg",
        description:
          "采用高山牦牛蹄为原材料，经过几小时炖、蒸、煮后再调味，软糯可口，富含丰富胶原蛋白，是一道男女老少都适合的菜品，被评为成都市郫都区十大川菜菜品。",
        descriptionEn:
          `Highland Red is a dish made from high-altitude mountain yak hooves. After several hours of stewing, steaming, and boiling, it is seasoned and becomes soft and delicious, rich in collagen, suitable for people of all ages and genders. It has been rated as one of the top ten Sichuan cuisine dishes in Pidu District, Chengdu.`,
      },
      {
        dishName: "蔬菜包",
        dishNameEn: "Vegetable Buns",
        cover: "/sheet1/6-0-2.jpg",
        description: "采用菠菜汁和面，秘制馅料，是一道非常养生的小吃，被评为郫都区特色小吃。",
        descriptionEn:
          `Vegetable Buns are made with spinach juice and secret stuffing, making it a very healthy snack. It has been rated as a characteristic snack in Pidu District.`,
      },
    ],
    address: "成都市郫都区郫筒街道望丛中路100号附4-5-6",
    addressEn: "Attachted No,45-6 of No. 100, Middle Wangcong Road, Pidu District, Chengdu",
    phone: "028-87929589",
    tel: "028-87929589",
    longLatitude: "103.90,30.79",
    longLatitudeId: "B001C7ZWPH",
    left: 857,
    top: 415,
    width: 67,
    height: 53,
  },

  {
    id: "小花园",
    rName: "小花园",
    belong: "郫筒街道",
    belongEn: "Pitong Street",
    rNameEn: "Little Garden Restaurant ",
    cover: ["/sheet1/7-1-1.jpg"],
    description:
      "“小花园”餐厅有近百年历史，以满足顾客需求为宗旨，秉承大众亲民的经营理念，重点突出川西坝子风味特色，传承发扬传统地道川菜味道。招牌菜“白果炖土鸡”“家常鲢鱼”被评为郫县第一届餐饮名菜。其优惠的价格、诚意满满的份量、高品质的服务，带给顾客“进店如进家”的体验。",
    descriptionEn: `The Little Garden Restaurant has a history of nearly a century. Its mission is to satisfy customers' needs and adhere to the management philosophy of being accessible to the general public. The restaurant highlights the flavor of Chuanxi Ba Zi, and inherits and promotes the authentic taste of traditional Sichuan cuisine. Signature dishes such as "Stewed Chicken with Gingko Nuts" and "Home-style Mandarin Fish" were named the first famous dishes in the catering industry in Pixian County. Its affordable prices, generous portions, and high-quality service provide customers with a "home away from home" experience.`,
    dishs: [
      {
        dishName: "白果炖土鸡",
        dishNameEn: "Stewed Chicken with White Nuts",
        cover: "/sheet1/7-0-1.jpg",
        description:
          "白果具有很高的实用、药用和保健价值，非常有利于身体健康，鸡肉富含蛋白质，白果炖鸡汤清肉嫩、清香美味、润肺益气、滋补保健，能够健胃补脾，清热解暑，降脂降压。",
        descriptionEn:
          `Stewed Chicken with White Nuts is a dish with high practical, medicinal, and health value. White nuts are very beneficial for physical health, while chicken meat is rich in protein. The stewed chicken soup with white nuts is clear, tender, fragrant, and delicious, moistening the lungs and nourishing the qi, tonifying the spleen and stomach, clearing heat, and reducing blood lipid and blood pressure.`,
      },
    ],
    address: "成都市郫都区郫筒街道西大街包家巷149号",
    addressEn: "No. 149, Baojia Lane, West Street, Pitong Street,Pidu District, Chengdu City",
    phone: "028-87862036",
    tel: "028-87862036",
    longLatitude: "103.88,30.81",
    longLatitudeId: "B001C7XJZE",
    left: 590,
    top: 414,
    width: 42,
    height: 51,
  },

  {
    id: "荣胜和鲜货火锅",
    belong: "犀浦街道",
    belongEn: "Xipu Street",
    rName: "荣胜和鲜货火锅",
    rNameEn: "Rongshenghe Fresh Hotpot",
    cover: ["/sheet1/8-1-1.jpg"],
    description:
      "成都“荣胜和鲜货火锅”于1998年在郫都区（曾名：郫县）创立，是鲜货火锅领鲜者，现全市拥有10家直营门店，曾先后获得“成都市郫都区最具人气火锅”“郫都区餐饮名店”等奖项，拥有自己的中厨供应链，所有产品均原产地采购，从而保证产品的高品质、高标准。",
    descriptionEn: `"Rongshenghe Fresh Hotpot" was founded in 1998 in Pidu District (formerly known as Pixian County) and is a pioneer in fresh food hot pot. It now has 10 direct-operated stores throughout the city and has won awards such as 'The Most Popular Hot Pot in Pidu District, Chengdu City' and 'Famous Restaurant in Pidu District'. With its own central kitchen supply chain, all products are purchased from their place of origin, ensuring high quality and standards.`,
    dishs: [
      {
        dishName: "招牌鲜切牛肉",
        dishNameEn: "Freshly Cut Beef",
        cover: "/sheet1/8-0-1.jpg",
        description:
          "招牌菜“招牌鲜切牛肉”：精选银川小黄牛，每日凌晨从市场采购，宰杀到上桌仅8个小时，纯手工现切，看得见的新鲜！",
        descriptionEn:
          ` Its signature dish is the "Freshly Cut Beef," made from carefully selected Yinchuan yellow cattle, purchased from the market every early morning and slaughtered within eight hours of serving. The beef is hand-cut and served fresh, ensuring its quality and freshness.`,
      },
    ],
    address: "成都市郫都区犀浦街道恒山南街292-302号1幢1-2层2号",
    addressEn:
      "No. 2, Floor 1-2, Building 1, No. 292-302, Hengshan South Street, Xipu Street, Pidu District, Chengdu",
    phone: "028-87835188",
    tel: "028-87835188",
    longLatitude: "103.96,30.75",
    longLatitudeId: "B001C807ZB",
    left: 1020,
    top: 639,
    width: 63,
    height: 63,
  },

  {
    id: "天房阁",
    belong: "犀浦街道",
    belongEn: "Xipu Street",
    rName: "天房阁",
    rNameEn: "Tianfangge Restaurant",
    cover: ["/sheet1/9-1-1.jpg"],
    description:
      "天房阁酒楼主营特色中餐，经营面积480㎡，建筑风格古朴典雅，具有川西阁楼的古色古香。底楼为用餐大厅，二楼包间，三楼棋牌。酒楼秉承“挖掘地方特色菜品，弘扬巴蜀美食文化”及‘精心烹制每一道菜肴，热情服务每一位顾客’的理念，将传统的美食和与现代生态营养学相融合，推出了独具特色的菜肴体系，其中“犀浦鲢鱼”被评为天府名菜，深得消费者喜爱。",
    descriptionEn: `Tianfangge Restaurant is a Chinese restaurant that specializes in local cuisine, with a business area of 480 square meters. The building has a quaint and elegant architectural style, with the ancient charm of a western Sichuan loft. The first floor is a dining hall, the second floor has private rooms, and the third floor is for chess and card games. The restaurant adheres to the concept of "digging up local specialty dishes and promoting Sichuan cuisine culture" and "carefully preparing each dish and warmly serving every customer". It combines traditional food with modern ecological nutrition and has launched a unique cuisine system. The "Xipu Lianyu (Silver Carp)" has been rated as a famous Sichuan dish and is deeply loved by consumers. `,
    dishs: [
      {
        dishName: "犀浦鲢鱼",
        dishNameEn: "Xipu Lianyu (Silver Carp)",
        cover: "/sheet1/9-0-1.jpg",
        description:
          "“犀浦鲢鱼”为四川名菜，被载入《中国菜谱》和《川菜烹饪事典》，至今有百余年历史，菜品采用郫都区淡水无鳞仔鲢、若干佐料、考究的火工精心而作。菜品颜色红亮，咸甜香辣，鱼肉软嫩，香鲜味美。食客络绎不绝，到店必点“犀浦鲢鱼”。",
        descriptionEn:
          `Xipu Lianyu is a Sichuan famous dish that has been included in the "Chinese Cookbook" and the "Sichuan Cuisine Cooking Dictionary" for more than a century. The dish uses freshwater scaleless carp from the Pidu District, as well as several seasonings and carefully crafted cooking techniques. The dish has a bright red color, with a savory, sweet, spicy and fragrant taste, and the fish meat is soft and tender. Customers come in a steady stream and must order the "Xipu Lianyu".`,
      },
    ],
    address: "成都市郫都区犀浦镇围城南街588号",
    addressEn: "No.588, South Weicheng  Street, Xipu Town, Pidu District, Chengdu.",
    phone: "028-87856777",
    tel: "028-87856777",
    longLatitude: "103.96,30.75",
    longLatitudeId: "B001C8OAZU",
    left: 1110,
    top: 717,
    width: 57,
    height: 69,
  },

  {
    id: "梦桐泉酒店",
    belong: "红光街道",
    belongEn: "Hongguang Street",
    rName: "梦桐泉酒店",
    rNameEn: "Mengtongquan Hotel Hotel",
    cover: ["/sheet1/10-1-1.jpg"],
    description:
      "梦桐泉川西文化酒店为成都劲浪集团旗下文化酒店品牌，于2018年重新装修升级，酒店距离成都仅18公里，北邻三道堰文化水乡，南邻望丛祠，园区占地220亩，绿化覆盖率达80%，园区上千种名贵植物花卉，一年四季均有妙景，自然生态构建度假休闲绝佳环境，更拥有国内少有的百年川西民居，其保存之完善，可再现川西历史底蕴。",
    descriptionEn: ` The Mengtongquan Hotel Cultural Hotel is a cultural hotel brand under the Chengdu Jinglang Group. It was renovated and upgraded in 2018. The hotel is only 18 kilometers away from Chengdu, and it is located at north of the Sandao Dam Cultural Water Town and south of the Wangcong Temple. The park covers an area of 220 acres, with a green coverage rate of 80%. There are thousands of rare plants and flowers in the park, and there are wonderful views throughout the year. The natural and ecological environment creates an excellent environment for vacation and leisure, and it also has rare hundred-year-old houses of Western Sichuan, which are well-preserved and can reproduce the historical heritage of Western Sichuan.`,
    dishs: [
      {
        dishName: "香辣江团",
        dishNameEn: "Spicy and Fragrant Fish",
        cover: "/sheet1/10-0-1.jpg",
        description:
          "江团口感爽滑，极致鲜美。民间有“不食江团，不知鱼味”之说，其肉嫩味鲜美，富含脂肪，无细刺，蛋白质含量为13.7%，脂肪为4.7%，被誉为淡水食用鱼中的上品。采用香辣口味烹饪，菜品色、香、味俱佳，适合各个年龄段的人群。",
        descriptionEn: `The fish Jiangtuan is refreshing and the taste is extremely delicious. There is a saying among the people that "if you don't eat Jiangtuan, you don't know the taste of fish". Its meat is tender and delicious, rich in fat, without fine thorns, with a protein content of 13.7% and fat of 4.7%, and it is known as the top-grade edible fish in freshwater. It is cooked with spicy and fragrant flavors, and the dish has a good color, aroma, and taste, suitable for people of all ages.`,
      },
    ],
    address: "成都市郫都区红光街道宋家林村5组301号",
    addressEn:
      "No. 301, Group 5, Songjialin Village, Hongguang Street, Pidu District, Chengdu City.",
    phone: "028-87806541",
    tel: "028-87806541",
    longLatitude: "103.90,30.83",
    longLatitudeId: "B001C94VZ3",
    left: 738,
    top: 297,
    width: 96,
    height: 87,
  },

  {
    id: "印象泰和园",
    belong: "友爱镇",
    belongEn: "YouAi Town",
    rName: "印象泰和园",
    rNameEn: "Taihe Garden Banquet Art Center",
    cover: ["/sheet1/11-1-1.jpg"],
    description:
      "泰和园宴会艺术中心是成都市郫都区唯一一家五星级婚礼文化宴会庄园，园区以东南亚及简欧风、ins网红风为主，呈现生活美学与艺术韵味相融合场景。30多亩的园区打造了多个差异化、ins风打卡点。经典的白色墙体搭配自然绿植、简约大气的原宿风建筑，每一个细节均透露出非凡的艺术气息，每一个角落都拥有细节控不可错过的美照打卡元素。",
    descriptionEn: `Taihe Garden Banquet Art Center is the only five-star wedding cultural banquet manor in Pidu District, Chengdu. The park is mainly based on Southeast Asian and simple European styles, presenting a fusion of life aesthetics and artistic charm. The park covers an area of more than 30 acres and has created several differentiated ins-style check-in points. The classic white walls are matched with natural green plants and simple and atmospheric Harajuku-style architecture. Every detail reveals an extraordinary artistic atmosphere, and every corner has photo-worthy elements that detail-oriented people should not miss.`,
    dishs: [
      {
        dishName: "石锅海参饭",
        dishNameEn: "Stone Pot Sea Cucumber Rice",
        cover: "/sheet1/11-0-1.jpg",
        description:
          "“石锅海参焖饭”采用上等南海海参及泰香米制作而成，寓意家和米香，幸福一生，是泰和园自创特色招牌菜。桌桌必点，深受客户青睐。菜品不仅具有增强人体能力，补肾益精，强身健体的功效，还可以使肌肤充盈、皱纹减少、消除面部色斑，美容养颜。",
        descriptionEn: `The "Stone Pot Sea Cucumber Rice" is a signature dish created by Taihe Garden using premium South China Sea cucumbers and Thai fragrant rice, symbolizing the fragrance of home and happiness for a lifetime. It is a must-order dish on every table and is highly praised by customers. The dish not only enhances the body's abilities, nourishes the kidneys and strengthens the body, but also makes the skin plump, reduces wrinkles, eliminates facial blemishes, and promotes beauty and skincare.`,
      },
    ],
    address: "成都市郫都区友爱镇升平村266号",
    addressEn: "No. 266, Shengping Village, Youai Town, Pidu District, Chengdu.",
    phone: "028-87883777",
    tel: "028-87883777",
    longLatitude: "103.85,30.82",
    longLatitudeId: "B0HU1CBRJY",
    left: 621,
    top: 465,
    width: 54,
    height: 57,
  },

  {
    id: "杨鸡肉陌上人家喜宴荟休闲庄",
    rName: "杨鸡肉陌上人家喜宴荟休闲庄",
    belong: "友爱镇",
    belongEn: "YouAi Town",
    rNameEn: "Yang‘s Chicken Moshangrenjia XiyanHui Leisure Village",
    cover: ["/sheet1/12-1-1.jpg"],
    description:
      "传承三代人，专注一只鸡。“杨鸡肉”作为郫都区特色餐饮名店，在二十多年的经营过程中，一直深受广大消费者的喜爱。 杨鸡肉陌上人家喜宴荟是“杨鸡肉”品牌下一家以喜宴和特色餐饮消费为经营业态的大型四星级农家乐 ，占地三十余亩，整体如同苏州园林，移步换形，处处美丽，是理想的休闲、娱乐、商务会议、大型喜宴举办地。",
    descriptionEn: `"Yang's chicken" is a famous restaurant specializing in chicken dishes in the district of Pidu, Chengdu. With a history of three generations, it has been favored by consumers for over 20 years. "Yang's chicken MoShang RenJia XiYanHui" is a four-star agritainment under the "Yang's chicken" brand, which focuses on banquets and specialty cuisine consumption. Covering an area of over 30 acres, it is designed like a Suzhou-style garden, with beautiful scenery at every turn, making it an ideal place for leisure, entertainment, business meetings, and large-scale banquets.`,
    dishs: [
      {
        dishName: "杨鸡肉",
        dishNameEn: "Yang's Chicken",
        cover: "/sheet1/12-0-1.jpg",
        description:
          "“杨鸡肉”发源于郫都区，是一道热卖了27年的特色菜品，因其创始人姓杨，招牌菜品以凉拌鸡肉为主，故被食客美誉为“杨鸡肉”。“杨鸡肉”为了保证鸡肉的纯正美味，尽可能选用农家黄皮跑山仔鸡公作主料。鸡肉分为红味、白味两种，红味麻、辣、香味尽在其中，白味咸鲜味宜，清淡爽口，鸡肉入口味纯化渣。",
        descriptionEn: `"Yang's Chicken" is a famous restaurant specializing in chicken dishes in the district of Pidu, Chengdu. With a history of three generations, it has been favored by consumers for over 20 years. Yang‘s Chicken Moshangrenjia XiyanHui Leisure Village is a four-star agritainment under the "Yang's Chicken" brand, which focuses on banquets and specialty cuisine consumption. Covering an area of over 30 acres, it is designed like a Suzhou-style garden, with beautiful scenery at every turn, making it an ideal place for leisure, entertainment, business meetings, and large-scale banquets.`,
      },
    ],
    address: "成都市郫都区友爱镇升平村7组207号（太清路）",
    addressEn: "No. 207, Group 7, Shengping Village, Youai Town, Pidu district, Chengdu City",
    phone: "028-87928858",
    tel: "028-87928858",
    longLatitude: "103.85,30.82",
    longLatitudeId: "B001C80VFO",
    left: 100000,
    top: 100000,
    width: 100000,
    height: 100000,
  },

  {
    id: "陈府家宴",
    rName: "陈府家宴",
    rNameEn: "Chen’s Family Banquet Restaurant",
    cover: ["/sheet1/13-1-1.jpg"],
    belong: "友爱镇",
    belongEn: "YouAi Town",
    description:
      "陈府家宴创办于2007年，至今已有16载历史，其环境优雅精致，是朋友聚会、家庭聚会、商务洽谈优选去处。陈府家宴秉承“传承经典、创新美味”的经营理念，坚持做好精品川菜餐厅。在保持传统川菜技艺和品味的基础上不断优化和丰富菜品、推陈出新，“老妈甜烧白”“大刀干拌猪头”“扣肉青海大黄菇”等传统精品菜品和十多道传统精品小吃，深受广大消费者的赞誉。“陈府家宴，味在家中”的理念深刻根植于郫都区百姓的记忆之中。",
    descriptionEn: `Chen’s Family Banquet Restaurant was founded in 2007 and has a history of 16 years. Its elegant and exquisite environment makes it a preferred destination for friends gatherings, family gatherings, and business negotiations. Chen's Family Feast adheres to the business philosophy of "inheritance of classics, innovation of delicacies", and insists on being a fine Sichuan cuisine restaurant. While maintaining the traditional Sichuan cuisine skills and taste, it constantly optimizes and enriches its menu, introduces new dishes, and has received high praise from consumers for traditional specialties such as "Sweet Braised White Radish", "Dried Pig Head in Chopped Sauce", and "Braised Pork with Qinghai Big Yellow Mushroom" and more than ten traditional snack specialties. The concept of "Chen's Family Feast, the taste of home" is deeply rooted in the memory of the people of Pidu District.`,
    dishs: [
      {
        dishName: "老妈甜烧白",
        dishNameEn: "Lao Ma Sweet Braised Pork",
        cover: "/sheet1/13-0-2.jpg",
        description:
          "三代祖传技艺，二十多年来，老妈甜烧白以其鲜香甜糯、丰腴形美、色泽红亮、肥而不腻的特点令各路美食爱好者这道传统名菜赞不绝口，已成为陈府家宴必点菜肴。",
        descriptionEn: `Lao Ma Sweet Braised Pork is a traditional famous dish that has been passed down for three generations. For more than 20 years, it has been highly praised by food lovers for its fresh and sweet taste, plump and beautiful appearance, bright red color, and not-too-greasy texture. It has become a must-have dish at Chen’s Family Banquet Restaurant.`,
      },
      {
        dishName: "扣肉青海大黄菇",
        dishNameEn: "Braised Pork with Qinghai Yellow Mushroom",
        cover: "/sheet1/13-0-1.jpg",
        description:
          "大黄菇食材取自于青海、阿坝州、甘孜州等藏区草原，每年6—7月为采摘期。此食材引入陈府家宴后，餐厅采用上海梅林扣肉与其巧妙组合后清炒、黄焖，使其味道鲜美香脆，口感极致，营养丰富。",
        descriptionEn: `The mushroom used in the dish is sourced from the grasslands of Tibet, Qinghai, Aba Prefecture, and Garze Prefecture. The mushroom is only harvested from June to July each year. After introducing this ingredient, the restaurant ingeniously combines it with Shanghai-style Meilin braised pork and stir-fries it with the mushroom, making it delicious, crispy, and nutritious. Currently, this dish can only be found at this restaurant in Pidu District.`,
      },
    ],
    address: "成都市郫都区友爱镇升平社区7组25号",
    addressEn: "No. 25, Group 7, Shengping Community, Youai Town, Pidu district, Chengdu City",
    phone: "028—61775533",
    tel: "028—61775533",
    longLatitude: "103.85,30.82",
    longLatitudeId: "B0FFGOO33E",
    left: 516,
    top: 480,
    width: 57,
    height: 39,
  },

  {
    id: "竹里湾乡村酒店",
    rName: "竹里湾乡村酒店",
    rNameEn: "Zhuliwan Country Hotel",
    cover: ["/sheet1/14-1-1.jpg"],
    belong: "友爱镇",
    belongEn: "YouAi Town",
    description:
      "竹里湾乡村酒店成立于2008年，距离成都市区19公里，整体环绕在半岛之上，依托原有竹林盘与老河湾进行精心打造和巧妙构筑，形成了湖光桥影、竹林深茂的自然风光。酒店占地30余亩，特色私密小院20座，客房37间，是集餐饮、住宿、娱乐、会议、宴席于一体的四星级乡村酒店，可同时容纳800人就餐。",
    descriptionEn:
      `Established in 2008, Zhuliwan Country Hotel is located 19 kilometers away from downtown Chengdu. It is built on a peninsula surrounded by a bamboo forest and an old river bay, creating a picturesque natural scenery of lakes, bridges, shadows, and lush bamboo groves. The hotel covers an area of over 30 acres, with 20 unique private courtyards and 37 guest rooms. It is a four-star country hotel that integrates dining, accommodation, entertainment, meetings, and banquets, and can accommodate up to 800 people for dining at the same time.`,
    dishs: [
      {
        dishName: "菊花鱼",
        dishNameEn: "Chrysanthemum Fish",
        cover: "/sheet1/14-0-1.jpg",
        description:
          "菊花鱼是一道传统名菜，成菜宛如朵朵盛开的菊花，造型逼真，色泽鲜艳，散发出阵阵诱人的芳香；吃起来口感外酥里嫩，酸甜爽口。多年来，此菜一直长兴不衰，深受各地食客的喜爱，尤其是妇女及儿童。",
        descriptionEn: `The Ju Hua Yu (Chrysanthemum Fish) is a traditional famous dish that looks like blooming chrysanthemums when served, with a realistic appearance, bright colors, and tempting fragrance. It has a crispy outer layer and tender, sour and sweet flesh inside, making it enjoyable for customers ,especially women and children.`,
      },
    ],
    address: "成都市郫都区友爱镇顺春路623号",
    addressEn: "No. 623, Shunchun Road, Youai Town, Pidu district, Chengdu",
    phone: "028-67500786",
    tel: "028-67500786",
    longLatitude: "103.84,30.81",
    longLatitudeId: "B001C8U031",
    left: 573,
    top: 582,
    width: 42,
    height: 57,
  },

  {
    id: "徐家大院",
    rName: "徐家大院",
    rNameEn: "Xu Family Restaurant & Homestay",
    cover: ["/sheet1/15-1-1.jpg"],
    belong: "友爱镇",
    belongEn: "YouAi Town",
    description:
      "徐家大院是中国第一家农家乐，也是全国乡村旅游典范，是一处集餐饮美食、商务会议、酒店住宿、观光体验旅游为一体的综合型度假场所。作为中国农家乐发源地，徐家大院历经了四个时期的发展，曾多次被CCTV4专题报道。",
    descriptionEn: `Xu Family Restaurant & Homestay is the first agritainment and a model for countryside tourism in China. It is a comprehensive resort that combines dining, business meetings, hotel accommodation, sightseeing, and experiential tourism. As the birthplace of Chinese agritainment, Xu Family Courtyard has undergone four stages of development and has been featured in several special reports by CCTV4.`,
    dishs: [
      {
        dishName: "青椒鲍鱼鸡",
        dishNameEn: "Abalone and Chicken with Green Pepper",
        cover: "/sheet1/15-0-1.jpg",
        description:
          "青椒鲍鱼鸡在川菜青椒鸡的基础上加以改良，增加了鲍鱼的爽脆口感，具有咸鲜脆嫩、鲍鱼香味浓郁、椒香味浓郁特点。",
        descriptionEn: `Abalone and Chicken with Green Pepper is an improved version of Sichuan-style pepper chicken with added crunchy texture of abalone. It has a characteristic flavor of salty freshness, strong abalone aroma, and peppery fragrance.`,
      },
      {
        dishName: "山药牛骨肉",
        dishNameEn: "Beef Shank with Yam",
        cover: "/sheet1/15-0-2.jpg",
        description:
          "山药牛骨肉牛肉高蛋白低脂肪，营养价值十分高，且有补中益气、滋养脾胃、强健筋骨、化痰息风、止渴止涎的功效，并能起暖胃的作用十分适合在冬天食用。",
        descriptionEn: `Beef Shank with Yam is a high-protein, low-fat beef dish with high nutritional value. It has the effects of tonifying the middle and benefiting Qi, nourishing the spleen and stomach, strengthening muscles and bones, relieving phlegm and wind, and quenching thirst. It is especially suitable for consumption in winter as it has a warming effect on the stomach.`,
      },
    ],
    address: "成都市郫都区友爱镇农科村1组80号",
    addressEn: "No. 80, Group 1, Nongke Village, Youai Town, Pidu district, Chengdu",
    phone: "028-87961015",
    tel: "028-87961015",
    longLatitude: "103.79,30.82",
    longLatitudeId: "B001C0A822",
    left: 145,
    top: 520,
    width: 73,
    height: 73,
  },

  {
    id: "铜壶苑鑫苑",
    rName: "铜壶苑鑫苑",
    rNameEn: "Tonghuyuan Xinyuan Fish Restaurant",
    cover: ["/sheet1/16-1-1.jpg"],
    belong: "友爱镇",
    belongEn: "YouAi Town",
    description:
      "铜壶苑成立于2006年，主要经营宴席、茶坊及火锅，优越的位置、优质的服务、可口的菜品为铜壶苑的发展奠定了良好的口碑。“铜壶苑·鑫缘”坐落于风光秀丽的清水河边，是一家时尚、休闲的鱼鲜酒楼，立足于高标准、优环境，面对中档消费顾客，受到各方人士的青睐，是婚宴、寿宴、商务聚会的理想场所。",
    descriptionEn: `Established in 2006, Tonghu Yuan mainly operates banquets, teahouses, and hot pot. Its prime location, quality service, and delicious cuisine have established a good reputation for Tonghuyuan. "Tonghuyuan · Xinyuan" is located by the beautiful Qingshui River and is a trendy and leisurely fish restaurant. With high standards and a comfortable environment, it caters to mid-range customers and is favored by all. It is an ideal place for weddings, birthday banquets, and business gatherings.`,
    dishs: [
      {
        dishName: "火鞭牛肉",
        dishNameEn: "Huobian Beef",
        cover: "/sheet1/16-0-1.jpg",
        description:
          "“火鞭牛肉”是铜壶苑在郫都区太和场牛肉的基础上完善的一道菜品，改刀后晾晒形式鞭炮，故名火鞭牛肉。选用三年以上的新鲜黄牛腿肉改刀、腌制、风干烟熏而成，成色泽红亮、香气浓郁，软硬适度，入口化渣，回味悠长。2021年被评为“成都名菜”。",
        descriptionEn: ` "Huobian Beef" is a dish perfected by Tonghuyuan based on the beef from Taihechang in Pidu district. The beef is sliced and dried in the shape of firecrackers, hence the name "Huobian Beef" It is made from fresh yellow cattle leg meat that is over three years old, cut, marinated, air-dried, and smoked to produce a bright red color, a rich aroma, a moderate texture, and a long-lasting aftertaste. In 2021, it was named "Chengdu Famous Dish".`,
      },
    ],
    address: "成都市郫都区友爱镇升平村7组209号",
    addressEn: "No. 209, Group 7, Shengping Village, Youai Town, Pidu district, Chengdu",
    phone: "028-87806333",
    tel: "028-87806333",
    longLatitude: "103.85,30.82",
    longLatitudeId: "B0FFF53EFV",
    left: 100000,
    top: 100000,
    width: 100000,
    height: 100000,
  },

  {
    id: "徐记尚河渔庄",
    belong: "三道堰镇",
    belongEn: "SanDaoYan Town",
    rName: "徐记尚河渔庄",
    rNameEn: "Xu's Shanghe Fishing Village",
    cover: ["/sheet1/17-1-1.jpg"],
    description:
      "徐记尚河渔庄位于三道堰镇沙西线与二绕收费站交汇处，在古老的蟇水河畔，专业经营各类鱼鲜，占地9000多平米，可同时容纳800人用餐，是一家经营十余年的三星级农家乐。徐记尚河渔庄有着别具一格的川西民居建筑风格，同时整个环境都体现出了蟇水河悠久的水文化，河两岸林木葱茏，树林间鸟鸣婉转。渔庄庭院中小桥流水潺潺、群鱼惬意；长廊上河风习习，水波不兴；雅间旁水车柳荫，古乡古韵。宴会大厅宽敞明亮，装饰别致。",
    descriptionEn: `Xu's Shanghe Fishing Village is located at the intersection of Shaxi Road and Erhuan Toll Station in Sandaoyan Town, by the ancient Mashui River. It specializes in various kinds of fresh fish and covers an area of over 9,000 square meters, accommodating up to 800 diners at the same time. It is a three-star agritainment with over a decade of operation. Xuji Shanghe Fishing Village has a unique Sichuan-western style architecture, and the entire environment reflects the long-standing hydro culture of Mashui River, with lush forests and birdsong. The courtyard of the fishing village has small bridges and flowing water, with contented fish swimming around; the veranda has the river breeze blowing, and the water ripples slightly; the elegant room is shaded by the willow of the waterwheel, with the ancient style of the hometown. The banquet hall is spacious and bright with exquisite decoration.`,
    dishs: [
      {
        dishName: "豆瓣鱼花",
        dishNameEn: "Douban-Flavored (The spicy bean paste) Fish Slices",
        cover: "/sheet1/17-0-1.jpg",
        description:
          "成菜色泽红亮，外酥内嫩，鱼花外形美观，豆瓣味浓郁。鲟鱼中含有大量的氨基酸，可以快速补充体内的氨基酸，能够增强机体免疫力，同时具有解毒功效。长期吃鲟鱼肉，对治疗腰痛以及肾病具有一定的预防作用。曾在2015年荣获郫县大众喜爱菜品，在2019年荣获郫都名菜称号。",
        descriptionEn: `The dish have a bright red color, crispy exterior, tender interior, beautiful fish slices, and a rich spicy bean paste flavor. Sturgeon contains a large amount of amino acids, which can quickly replenish the body's amino acids, enhance immunity, and has detoxifying effects. Long-term consumption of sturgeon meat has a certain preventive effect on treating back pain and kidney disease. It was awarded the popular dish in Pixian County in 2015 and the title of famous dish in Pidu District in 2019.`,
      },
    ],
    address: "成都市郫都区三道堰镇马街社区蜀源大道299号",
    addressEn:
      "No. 299 Shuyuan Avenue, Majie Community, Sandaoyan Town, Pidu district, Chengdu",
    phone: "028-87910353",
    tel: "028-87910353",
    longLatitude: "103.92,30.89",
    longLatitudeId: "B0FFGSF5SE",
    left: 100000,
    top: 100000,
    width: 100000,
    height: 100000,
  },

  {
    id: "容和苑·向记大花鲢",
    rName: "容和苑·向记大花鲢",
    rNameEn: "Ronghe Garden: Xiang’s Silver Carp Dish",
    cover: ["/sheet1/18-1-1.jpg"],
    description:
      "该店成立于 2009 年 4 月，在清水河畔临水而建，是一家以鱼为特色，传统川菜为主营的特色餐饮店。庭院深深，绿树掩映，小桥流水，鸟语花香，曾获“郫都区餐饮名店”“成都市绿色餐饮示范店”等荣誉称号。",
    descriptionEn: `This restaurant was established in April 2009, situated by the side of the Qingshui River. It is a unique dining establishment that specializes in fish dishes and traditional Sichuan cuisine. With a deep courtyard adorned with lush trees, flowing streams, and the melodies of birds, it has earned prestigious accolades such as the "Renowned Restaurant in Pidu District" and "Green Restaurant Demonstration Store in Chengdu City."`,
    dishs: [
      {
        dishName: "回锅鱼",
        dishNameEn: "Twice-cooked Fish",
        cover: "/sheet1/18-0-1.jpg",
        description:
          "菜品根据回锅肉演变而来，通过腌制、挂糊、软炸、炒制等手法，融入郫县豆瓣，使菜品色泽红亮、外酥内嫩、咸鲜微辣，是一道老少皆宜的菜品佳肴。",
        descriptionEn: `The dish originated from the evolution of "Hui Guo Rou" (Twice-Cooked Pork). It is prepared by marinating, coating, soft frying, and stir-frying techniques, incorporating Pixian Douban (douban-flavored rice) to give the dish a bright red color, crispy exterior, tender interior, and a mildly salty and spicy taste. It is a dish that appeals to people of all ages, making it a delightful culinary choice.`,
      },
    ],
    address: "郫都区现代工业港南区452号",
    addressEn: "No. 452, South Area of Modern Industrial Port, Pidu District",
    phone: "028-87881186",
    tel: "028-87881186",
    longLatitude: "103.88,30.77",
    longLatitudeId: "B0FFFW25OD",
    left: 725,
    top: 714,
    width: 77,
    height: 66,
  },

  {
    id: "三合居盐城人家",
    rName: "三合居盐城人家",
    rNameEn: "Sanheju：Yanchengrenjia Restaurant",
    cover: ["/sheet1/19-1-1.jpg"],
    description:
      "犀浦地区十一年老店，以盐帮川菜为特色的综合性餐饮企业。在当地拥有良好的口碑，曾获“郫都区餐饮名店”“郫都区首届大千鲢鱼宴团体第一名”等荣誉称号。",
    descriptionEn: `"Xipu's well-established restaurant for eleven years, a comprehensive catering enterprise specializing in Yanbang Sichuan cuisine. It enjoys a good reputation locally and has been honored with titles such as "Renowned Restaurant in Pidu District" and "First Place of Group in the Inaugural Dqqian Lianyu Banquet in Pidu District".`,
    dishs: [
      {
        dishName: "鲜锅兔",
        dishNameEn: "Rabbit Pot",
        cover: "/sheet1/19-0-1.jpg",
        description:
          "源于自贡最具特色的菜品之一，特点是“鲜、香、嫩、滑、辣”，肉质饱满，汁水丰富，入口爽滑，细嫩化渣，作为自贡盐帮饮食文化的代表，味美鲜香至极！",
        descriptionEn: `Rabbit pot is one of the most distinctive dishes originating from Zigong. It is characterized by its freshness, aroma, tenderness, smoothness, and spiciness. The meat is plump, rich in juices, and has a smooth texture that melts in the mouth. As a representative of Zigong's Yanbang culinary culture, it offers an incredibly delicious and fragrant taste experience.`,
      },
    ],
    address: "郫都区犀浦镇双铁路41号（双铁广场对面）",
    addressEn: "No. 41 Shuangtie Road, Xipu Town, Pidu District (Opposite Shuangtie Square)",
    phone: "028-61775177",
    tel: "028-61775177",
    longLatitude: "103.97,30.76",
    longLatitudeId: 'B001C8VTFG',
    left: 1095,
    top: 636,
    width: 65,
    height: 75,
  },
];
