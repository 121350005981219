<template>
  <div class="page-content" :class="!isShowContiner ? 'loading-ing' : ''">
    <div class="percent" v-show="!isShowContiner">{{ percent }}%</div>
    <img src="@/assets/home/panda_right.gif" class="loading" v-show="!isShowContiner" />
    <!-- <img src="@/assets/home/loading.png" class="loading" v-show="!isShowContiner" /> -->
    <div class="map-content" v-show="isShowContiner">
      <div class="mask-content" v-if="!isHiddenMask">
        <img src="@/assets/home/mask.png" class="mask" />
        <img src="@/assets/home/ok.png" class="ok" @click="clickOk" />
      </div>
      <img :src="isEnglish ? head_nav_en : head_nav" class="nav" @click="clickNav('导航')" />
      <img src="@/assets/home/language_switch.png" class="switch" @click="switchLanguage" />
      <img :src="isShowList ? bg : home_bg" class="content-bg" />
      <div class="head">
        <img src="@/assets/home/index_title.png" class="head-title" />
      </div>
      <div class="footer" :class="isEnglish ? 'is-en' : ''">
        <div class="nav-item" v-for="(item, index) in isEnglish ? navListEn : navList" :key="index">
          <img
            :src="isEnglish ? item.icon_en : item.icon"
            class="icon"
            :class="isEnglish ? 'is-en' : ''"
            @click="clickNav(item.name)"
          />
        </div>
      </div>
      <div class="bottom-text">
        {{
          isEnglish
            ? "By：Bureau Of Commerce of Pidu District, Chengdu"
            : "编制单位：成都市郫都区商务局"
        }}
      </div>
      <div class="container">
        <vue-zoom class="img-zoom">
          <div class="bg-content" :class="isEnglish ? 'en' : ''" ref="scaleContent">
            <!-- 切图缩放比例，3000 / 1494 = 2 -->
            <div
              class="point"
              v-show="item.width"
              :style="{
                // todo: 用于迅速找到点位
                // background: item.id === '蜀滋源' ? 'red' : '',
                left: item.left * 2 + 'rem',
                top: item.top * 2 + 'rem',
                width: item.width * 2 + 'rem',
                height: item.height * 2 + 'rem',
              }"
              @click="showDialog(item, index)"
              v-for="(item, index) in allSheets"
              :key="index"
            >
              <div class="gif-content" v-show="isShowPandaIndex === index">
                <img
                  src="@/assets/home/panda_right.gif"
                  class="panda"
                  :class="isShowPandaIndex === index ? 'move' : ''"
                  :style="{ height: item.height * 2 + 'rem', left: leftToRight + 'rem' }"
                />
              </div>
            </div>
            <img
              v-show="!isEnglish"
              src="@/assets/home/map_bg.png"
              class="bottom-map"
              @load="onloadBg"
            />
            <img v-show="isEnglish" src="@/assets/home/map_bg_en.png" class="bottom-map" />
          </div>
        </vue-zoom>
      </div>
      <van-popup v-model="isShowDialog" :style="{ 'background-color': 'transparent' }">
        <DetailDialog
          :show="isShowDialog"
          @goBack="isShowDialog = false"
          :detail="currentDetail"
          :isEnglish="isEnglish"
        />
      </van-popup>
      <StoreList
        v-if="isShowList"
        @goHome="isShowList = false"
        :navType="currentNavType"
        :isEnglish="isEnglish"
      />
    </div>
  </div>
</template>

<script>
import { setSession, getSession, setLocal, getLocal } from "@/utils/session";
import { allSheets } from "@/const/allMap";
import bg from "@/assets/home/bg.png";
import home_bg from "@/assets/home/home_bg.png";
import foot_icon_0 from "@/assets/home/foot_icon_0.png";
import foot_icon_1 from "@/assets/home/foot_icon_1.png";
import foot_icon_2 from "@/assets/home/foot_icon_2.png";
import foot_icon_3 from "@/assets/home/foot_icon_3.png";
import foot_icon_4 from "@/assets/home/foot_icon_4.png";
import foot_icon_0_en from "@/assets/home/foot_icon_0_en.png";
import foot_icon_1_en from "@/assets/home/foot_icon_1_en.png";
import foot_icon_2_en from "@/assets/home/foot_icon_2_en.png";
import foot_icon_3_en from "@/assets/home/foot_icon_3_en.png";
import head_nav from "@/assets/home/head_nav.png";
import head_nav_en from "@/assets/home/head_nav_en.png";
import DetailDialog from "./DetailDialog";
import VueZoom from "./zoom";
import StoreList from "./StoreList";

export default {
  components: { DetailDialog, StoreList, VueZoom },
  data() {
    return {
      bg,
      home_bg,
      isHiddenMask: getLocal("isHiddenMask") || false,
      isEnglish: getSession("isEnglish") || false,
      isShowContiner: false, // 是否展示页面
      isShowDialog: false, // 是否展示弹窗
      isShowPandaIndex: -1, // 当前点击的点位
      leftToRight: 0, // 点击点位之后熊猫的初始位置
      percent: 0, // 加载进度值
      isShowList: false, // 是否展示列表页
      head_nav,
      head_nav_en,
      currentDetail: {},
      currentNavType: "",
      allSheets,
      navList: [
        {
          name: "市级名菜",
          icon: foot_icon_0,
          icon_en: foot_icon_0_en,
        },
        {
          name: "郫县豆瓣宴",
          icon: foot_icon_1,
          icon_en: foot_icon_1_en,
        },
        {
          name: "重点消费场景",
          icon: foot_icon_2,
          icon_en: foot_icon_2_en,
        },
        {
          name: "美食聚集区",
          icon: foot_icon_3,
          icon_en: foot_icon_3_en,
        },
        {
          name: "四川老字号",
          icon: foot_icon_4,
        },
      ],
      navListEn: [
        {
          name: "市级名菜",
          icon: foot_icon_0,
          icon_en: foot_icon_0_en,
        },
        {
          name: "郫县豆瓣宴",
          icon: foot_icon_1,
          icon_en: foot_icon_1_en,
        },
        {
          name: "重点消费场景",
          icon: foot_icon_2,
          icon_en: foot_icon_2_en,
        },
        {
          name: "美食聚集区",
          icon: foot_icon_3,
          icon_en: foot_icon_3_en,
        },
      ],
    };
  },
  mounted() {
    setSession("isEnglish", false);
    const interval = setInterval(() => {
      if (this.percent >= 100) {
        clearInterval(interval);
        return;
      }
      this.percent += 4;
    }, 120);
  },
  methods: {
    clickOk() {
      this.isHiddenMask = true;
      setLocal("isHiddenMask", true);
    },
    showDialog(item, index) {
      // panda移动时不可点击
      // 2025-02-27版本：英文情况下，没有引文名称的点位不可点击
      if (this.isShowPandaIndex > -1 || (this.isEnglish && !item.rNameEn)) return;
      this.leftToRight = -item.width;
      const interval = setInterval(() => {
        this.leftToRight += item.width / 140;
      }, 10);
      this.isShowPandaIndex = index;
      setTimeout(() => {
        this.isShowPandaIndex = -1;
        this.currentDetail = allSheets.find((cur) => cur.id === item.id);
        this.isShowDialog = true;
        this.leftToRight = 0;
        clearInterval(interval);
      }, 2000);
    },
    switchLanguage() {
      if (this.isShowPandaIndex > -1) return;
      const isEnglish = getSession("isEnglish");
      this.isEnglish = !isEnglish;
      setSession("isEnglish", !isEnglish);
    },
    onloadBg() {
      setTimeout(() => {
        this.isShowContiner = true;
      }, 3000);
    },
    clickNav(name) {
      if (this.isShowPandaIndex > -1) return;
      this.currentNavType = name;
      this.isShowList = true;
    },
  },
};
</script>

<style lang="less" scoped>
.page-content {
  width: 100vw;
  height: 100vh;
  position: relative;

  &.loading-ing {
    background-color: #023c96;
  }
}
@font-face {
  font-family: Facon;
  src: url("~@/assets/fonts/Facon-2.ttf");
}

.percent {
  font-family: Facon;
  font-size: 18px;
  color: #fdfcff;
  text-align: center;
  text-shadow: 0 0 10px rgba(159, 241, 249, 0.75);
  font-weight: 700;
  position: fixed;
  z-index: 1001;
  top: 35vh;
  width: 100vw;
}
.loading {
  position: fixed;
  z-index: 1001;
  left: calc(50vw - 38rem);
  top: 40vh;
  width: 76rem;
}

.map-content {
  position: relative;

  .mask-content {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    z-index: 111;

    .mask {
      width: 100vw;
    }

    .ok {
      width: 130rem;
    }
  }

  .content-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
  }

  .nav {
    position: fixed;
    top: 18rem;
    left: 20rem;
    width: 114rem;
  }

  .bottom-text {
    text-align: center;
    font-size: 14rem;
    color: #ffffff;
    line-height: 21rem;
    font-weight: 500;
    position: fixed;
    width: 100%;
    bottom: 24rem;
  }

  .switch {
    position: fixed;
    top: 24rem;
    right: 22rem;
    width: 22rem;
    z-index: 11;
  }

  .container {
    box-sizing: border-box;
    padding-top: 172rem;
    height: 100vh;
    user-select: none;
    touch-action: none;
    overflow: hidden;

    .img-zoom {
      position: absolute;
      top: 200rem;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 375rem;
      height: 240rem;
    }

    .bg-content {
      display: block;
      max-width: 3000rem;

      .bottom-map {
        width: 3000rem;
        image-rendering: -webkit-optimize-contrast;
      }

      .point {
        // background-color: aqua;
        // opacity: 0.5;
        position: absolute;
        .gif-content {
          .panda {
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
    }
  }

  .head {
    position: fixed;
    left: 33rem;
    top: 74rem;

    .head-title {
      width: 305rem;
    }
  }

  .footer {
    position: fixed;
    left: 0;
    bottom: 52rem;
    display: flex;
    flex-wrap: wrap;
    padding: 0 5rem;
    justify-content: center;

    &.is-en {
      padding: 0 11rem 0 20rem;
      bottom: 32rem;
      justify-content: space-between;
    }

    .nav-item {
      margin-bottom: 28rem;

      .icon {
        width: 110rem;

        &.is-en {
          width: 168rem;
        }
      }
    }
  }
}
</style>
